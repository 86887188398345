import { Button, Flex, Item, Key, ListView, View } from "@adobe/react-spectrum";
import CloseIcon from "@spectrum-icons/workflow/Close";
import { promptMatrix, PromptMatrixKey, suggestionsKeys } from "./prompts";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import notEmpty from "../../../utils/notEmpty";

/**
 * ItemTapTarget is a wrapper around the children of an Item in the ListView
 * component. It adds a div around the children that is used for tracking
 * analytics events.
 */
const ItemTapTarget: React.FC<
  React.PropsWithChildren<{
    label: string;
  }>
> = ({ label, children }) => {
  return (
    <div
      className="analytics-track-me"
      style={{ margin: "-1em", padding: "1em", width: 600 }}
      data-analytics-link-name={label}
      data-analytics-view-name="AI Prompt Selector"
    >
      {children ? children : label}
    </div>
  );
};

const PromptSelector: React.FC<{
  setSelfVisible: (isVisible: boolean) => void;
  setKey: (key: PromptMatrixKey) => void;
  isLoading: boolean;
}> = ({ setSelfVisible, isLoading, setKey }) => {
  const productFamily =
    useSelector((state: RootState) => state.case.caseObject)
      ?.productFamilyName || "";

  const lifecycleItemClicked = (rawKey: Key) => {
    if (isLoading) return;
    const key = `${rawKey}` as PromptMatrixKey;
    if (key.startsWith("_title")) return;

    // Immediate timeout allows for tracking events to fire
    setTimeout(() => {
      setKey(key as PromptMatrixKey);
      setSelfVisible(false);
    }, 0);
  };

  return (
    <View
      backgroundColor={"gray-100"}
      borderColor={"gray-400"}
      borderWidth={"thin"}
      borderRadius={"small"}
    >
      <Flex>
        <View flex={1} padding={"size-400"}>
          <h1 style={{ fontSize: "1.5em", fontWeight: "normal", margin: "0" }}>
            Tell me where are you in the lifecycle of this case?
          </h1>
        </View>
        <View padding={"size-100"}>
          <Button
            variant="secondary"
            onPress={() => setSelfVisible(false)}
            aria-label="Close prompt selector"
          >
            <CloseIcon />
          </Button>
        </View>
      </Flex>
      <div className="prompt-selector__progress-bar">
        <div className="prompt-selector__progress-node"></div>
        <div className="prompt-selector__progress-node"></div>
        <div className="prompt-selector__progress-node"></div>
        <div className="prompt-selector__progress-node"></div>
      </div>
      <ul className="prompt-selector__lifecycle">
        <li className="prompt-selector__lifecycle-step">
          <ListView
            width="100%"
            selectionMode="none"
            onAction={lifecycleItemClicked}
          >
            {[
              <Item key="_title-investigate">
                <strong>Investigating Case?</strong>
              </Item>,
              ...suggestionsKeys
                .filter(
                  (key) =>
                    !(
                      (key === "investigate-summary-commerce-project" &&
                        productFamily !== "Commerce") ||
                      key === "aem-non-cso-rca"
                    ),
                )
                .map((key) => (
                  <Item key={key}>
                    <ItemTapTarget label={promptMatrix[key].label} />
                  </Item>
                )),
            ]}
          </ListView>
        </li>
        <li className="prompt-selector__lifecycle-step">
          <ListView
            width="100%"
            selectionMode="none"
            onAction={lifecycleItemClicked}
            disabledKeys={["suggest-runbook"]}
          >
            <Item key="_title-working-on-case">
              <strong>Working on Case?</strong>
            </Item>
            <Item key="suggest-resolution">
              <ItemTapTarget label="Suggest Resolution" />
            </Item>

            <Item key="suggest-runbook">
              <ItemTapTarget label="Suggest Runbook">
                Suggest Runbook
                <sup style={{ color: "blue", opacity: 0.5 }}>WIP</sup>
              </ItemTapTarget>
            </Item>
          </ListView>
        </li>
        <li className="prompt-selector__lifecycle-step">
          <ListView
            width="100%"
            selectionMode="none"
            onAction={lifecycleItemClicked}
            disabledKeys={["wrap-ext", "wrap-comments"]}
          >
            {[
              <Item key="_title-wrap">
                <strong>Wrapping up Case?</strong>
              </Item>,
              ...suggestionsKeys
                .filter(
                  (key) =>
                    key === "aem-non-cso-rca" &&
                    ["Adobe Exp Manager", "Adobe Exp Platform"].includes(
                      productFamily,
                    ),
                )
                .map((key) => (
                  <Item key={key}>
                    <ItemTapTarget label={promptMatrix[key].label} />
                  </Item>
                )),
              <Item key="wrap-ext">
                <ItemTapTarget label="Closure Comments (Ext)">
                  Closure Comments (Ext)
                  <sup style={{ color: "blue", opacity: 0.5 }}>WIP</sup>
                </ItemTapTarget>
              </Item>,
              <Item key="wrap-comments">
                <ItemTapTarget label="Closure Comments">
                  Closure Comments
                  <sup style={{ color: "blue", opacity: 0.5 }}>WIP</sup>
                </ItemTapTarget>
              </Item>,
            ]}
          </ListView>
        </li>
        <li className="prompt-selector__lifecycle-step">
          <ListView
            width="100%"
            selectionMode="none"
            onAction={lifecycleItemClicked}
            disabledKeys={["other-draft", "other-2", "other-3"]}
          >
            {[
              <Item key="_title-other">
                <strong>Something else?</strong>
              </Item>,
              <Item key="other-knowledge-center">
                <ItemTapTarget label="Create Draft KB Article">
                  Create Draft KB Article
                </ItemTapTarget>
              </Item>,
            ].filter(notEmpty)}
          </ListView>
        </li>
      </ul>
    </View>
  );
};

export default PromptSelector;

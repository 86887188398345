import React from "react";
import {
  Divider,
  Flex,
  Grid,
  View,
  minmax,
  repeat,
} from "@adobe/react-spectrum";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import UserTicketWatchlistCard from "../components/userHomePage/UserTicketWatchlistCard";

const UserHomePage: React.FC = () => {
  document.title = "User Home";
  const userName = useSelector(
    (state: RootState) => state?.user?.userDetails?.name,
  );

  return (
    <View
      borderRadius="regular"
      backgroundColor="gray-50"
      paddingY="size-300"
      UNSAFE_className="data-container-wrapper"
      height="95%"
      overflow="auto"
      width="100%"
    >
      <Flex
        direction="row"
        alignContent="center"
        justifyContent="space-between"
        marginX="size-500"
      >
        <p className="user-page-parent-title">Hi {userName || ""}!</p>
      </Flex>
      <Divider
        size="S"
        marginTop="size-150"
        UNSAFE_className="divider-with-drop-shadow"
      />
      <View paddingY="size-300" paddingX="size-500">
        <Grid
          columns={repeat("auto-fit", minmax("450px", "1fr"))}
          gap="size-200"
        >
          <UserTicketWatchlistCard />
        </Grid>
      </View>
    </View>
  );
};

export default UserHomePage;

import React, { useEffect, useState } from "react";
import { Switch } from "@adobe/react-spectrum";
import { ToastQueue } from "@react-spectrum/toast";
import {
  useCheckWatchlistItemQuery,
  useRemoveFromWatchlistMutation,
  useAddToWatchlistMutation,
} from "../../services/supportInsights";

interface WatchlistSwitchProps {
  itemId: number;
  itemType: string;
}

const WatchlistSwitch: React.FC<WatchlistSwitchProps> = ({
  itemId,
  itemType,
}) => {
  const [isSwitchToggled, setIsSwitchToggled] = useState(false);
  const [removeFromWatchlist] = useRemoveFromWatchlistMutation();
  const [addToWatchlist] = useAddToWatchlistMutation();

  const handleToggle = async () => {
    let payload = { itemId: itemId, itemType: itemType };
    if (isSwitchToggled) {
      removeFromWatchlist({ payload })
        .unwrap()
        .then((response) => {
          setIsSwitchToggled(false);
          ToastQueue.positive(response.meta.message, { timeout: 3000 });
        })
        .catch((error) => {
          ToastQueue.negative(error.data.meta.message, { timeout: 3000 });
        });
    } else if (!isSwitchToggled) {
      addToWatchlist({ payload })
        .unwrap()
        .then((response) => {
          setIsSwitchToggled(true);
          ToastQueue.positive(response.meta.message, { timeout: 3000 });
        })
        .catch((error) => {
          ToastQueue.negative(error.data.meta.message, { timeout: 3000 });
        });
    }
  };

  const { data, isLoading } = useCheckWatchlistItemQuery(
    {
      itemId: itemId,
      itemType: itemType,
    },
    {
      skip: !itemId,
    },
  );

  useEffect(() => {
    if (data) {
      setIsSwitchToggled(data.data.isItemWatchlisted);
    }
  }, [data]);

  if (!data || isLoading) {
    return null;
  }

  return (
    <Switch
      UNSAFE_className="watchlist-switch-label"
      isEmphasized
      isSelected={isSwitchToggled}
      onChange={handleToggle}
    >
      {isSwitchToggled ? "Remove from Watchlist" : "Add to Watchlist"}
    </Switch>
  );
};

export default WatchlistSwitch;

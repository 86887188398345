import {
  ActionButton,
  Text,
  TagGroup,
  Item,
  TextField,
  TooltipTrigger,
  Tooltip,
  Tabs,
  TabList,
  TabPanels,
  Flex,
  ContextualHelp,
  Heading,
  Content,
} from "@adobe/react-spectrum";
import React from "react";
import prompt from "./prompt";
import { useLocation, useParams } from "react-router-dom";
import JiraTicketSummary from "./JiraTicketSummary";
import { useTicketHandler } from "../../../hooks/cfsTool/useTicketHandler";
import { LabelableProps, StyleProps } from "@react-types/shared";
import AddCircle from "@spectrum-icons/workflow/AddCircle";

type CfsTicketsProps = LabelableProps & StyleProps;

const CfsTickets: React.FC<CfsTicketsProps> = ({ label, ...styleProps }) => {
  const location = useLocation();
  const { jiraTicket } = useParams();
  const queryParams = new URLSearchParams(location.search);
  const queryJiraTickets = queryParams.getAll("jiraTickets");
  const querySummaryMode = queryParams.get("summaryMode") === "true";
  const allInitialJiraTickets = jiraTicket
    ? [...queryJiraTickets, jiraTicket]
    : queryJiraTickets;

  const [_, __, jiraTickets, handleAddTicketID, handleRemoveTicketID] =
    useTicketHandler(allInitialJiraTickets, prompt.supportedJiraPrefixes);

  const handleAddTicket = (ticketID: string) => {
    handleAddTicketID(ticketID);
    setTicketID("");
    setAddingTicket(false);
  };

  const [ticketID, setTicketID] = React.useState<string>("");
  let [addingTicket, setAddingTicket] = React.useState<boolean>(false);

  const emptyTags = <Text>No tickets defined</Text>;

  const jiraSummaryMode = (
    <Flex direction="row">
      <Tabs>
        <TabList>
          {jiraTickets.length > 0 ? (
            jiraTickets.map((id) => <Item key={id}>{id}</Item>)
          ) : (
            <Item key="notickets">no tickets</Item>
          )}
        </TabList>
        <TabPanels>
          {jiraTickets.length > 0 ? (
            jiraTickets.map((id) => (
              <Item key={id}>
                <JiraTicketSummary ticketId={id} />
              </Item>
            ))
          ) : (
            <Item key="notickets">{emptyTags}</Item>
          )}
        </TabPanels>
      </Tabs>
    </Flex>
  );

  const simpleMode = (
    <TagGroup
      height="100%"
      renderEmptyState={() => <div />}
      aria-label="Jira Tickets"
      onRemove={handleRemoveTicketID}
    >
      {jiraTickets.map((id) => (
        <Item
          href={`https://jira.corp.adobe.com/browse/${id}`}
          target="_blank"
          key={id}
        >
          {id}
        </Item>
      ))}
    </TagGroup>
  );

  const contextualHelp = (
    <ContextualHelp variant="info">
      <Heading>Jira Tickets</Heading>
      <Content>
        <Text>
          Add least one Jira tickets from the following Jira prefix(s){" "}
          <b>({prompt.supportedJiraPrefixes.join(", ")})</b>
        </Text>
      </Content>
    </ContextualHelp>
  );

  const jiraInput = (
    <TextField
      autoFocus
      label={label}
      value={ticketID}
      onFocusChange={(isFocused: boolean) => {
        setAddingTicket(isFocused);
        if (!isFocused && ticketID.length > 3) {
          handleAddTicket(ticketID);
        }
      }}
      onChange={setTicketID}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          e.preventDefault();
          handleAddTicket(ticketID);
        }
      }}
      {...styleProps}
    />
  );

  const addTicketButton = (
    <Flex direction="column">
      {label && (
        <Flex direction="row">
          <label className="cfs-system-prompt-label">{label}</label>
          {contextualHelp}
        </Flex>
      )}
      <ActionButton {...styleProps} onPress={() => setAddingTicket(true)}>
        <Text>Add Jira Ticket</Text>
        <AddCircle />
      </ActionButton>
    </Flex>
  );

  return (
    <Flex direction="column">
      {addingTicket ? jiraInput : addTicketButton}
      {querySummaryMode ? jiraSummaryMode : simpleMode}
    </Flex>
  );
};

export default CfsTickets;

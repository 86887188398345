import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store/store";
import {
  setAiArticle,
  setKbArticles,
  setKbArticlesFetched,
} from "../store/case/caseSlice";
import { useState } from "react";

export function useKbArticles(caseId: string) {
  const dispatch = useDispatch();
  let [isKbArticlesRefreshing, setKbArticlesRefreshing] = useState(false);
  const token = useSelector((state: RootState) => state.auth.token);

  const reload = () => {
    getKbArticles();
  };

  const getKbArticles = () => {
    const url = `${window.env.REACT_APP_API_BASE}knowledgeCenter/articles/${caseId}`;
    setKbArticlesRefreshing(true);
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "X-OKTA-Authorization": `Bearer ${token}`,
      },
    }).then((response) => {
      if (!response.body || !response.ok) {
        dispatch(
          setAiArticle({
            text: "Sorry, but AI suggestions are not available at the moment.",
            isLoading: false,
          }),
        );
      }
      const jsonData = response.json();
      jsonData.then((data) => {
        dispatch(setKbArticles(data?.data));
        dispatch(setKbArticlesFetched(true));
        setKbArticlesRefreshing(false);
      });
    });
  };

  return [isKbArticlesRefreshing, getKbArticles, reload] as const;
}

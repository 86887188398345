import React, { useEffect, useState } from "react";
import {
  Dialog,
  Button,
  TextField,
  DatePicker,
  Flex,
  View,
  DialogContainer,
  Heading,
  Divider,
  Content,
  ButtonGroup,
  Form,
} from "@adobe/react-spectrum";
import CrossSmall from "@spectrum-icons/ui/CrossSmall";
import { CODE, NAME } from "../../config/BannerConfig";
import { useOktaAuth } from "@okta/okta-react";
import {
  usePostBannerDataMutation,
  useUpdateBannerDataMutation,
} from "../../services/supportInsights";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import {
  DateValue,
  parseAbsoluteToLocal,
  now,
  getLocalTimeZone,
} from "@internationalized/date";
import { setBannerData } from "../../store/bannerSlice";
import { convertToISODateUTCTimeZone } from "../../utils/dateTimeUtils";
import { ToastQueue } from "@react-spectrum/toast";
interface BannerFormProps {
  onClose: () => void;
  onCancel: () => void;
  closeModal: () => void;
}

const BannerForm: React.FC<BannerFormProps> = ({ onClose, closeModal }) => {
  const dispatch = useDispatch();
  const [message, setMessage] = React.useState("");
  const [startDate, setStartDate] = useState<DateValue | null>(null);
  const [endDate, setEndDate] = useState<DateValue | null>(null);
  const bannerData = useSelector((state: RootState) => state.banner.bannerData);

  const [errors, setErrors] = useState({
    message: "",
    startDate: "",
    endDate: "",
  });
  const [postBannerData] = usePostBannerDataMutation();
  const [updateBannerData] = useUpdateBannerDataMutation();
  const { oktaAuth } = useOktaAuth();
  const formatDate = (date: Date | null): string => {
    if (!date) return "";
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const day = String(date.getUTCDate()).padStart(2, "0");
    const hours = String(date.getUTCHours()).padStart(2, "0");
    const minutes = String(date.getUTCMinutes()).padStart(2, "0");
    const seconds = String(date.getUTCSeconds()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  const handleFormSubmit = () => {
    const newErrors = {
      message: "",
      startDate: "",
      endDate: "",
    };
    let hasError = false;

    if (!message.trim()) {
      newErrors.message =
        "Please provide a Banner Message of up to 100 characters.";
      hasError = true;
    }
    if (!startDate) {
      newErrors.startDate = "Please provide the Start Date";
      hasError = true;
    }
    if (!endDate) {
      newErrors.endDate = "Please provide the End Date";
      hasError = true;
    }
    if (startDate && endDate && startDate.compare(endDate) >= 0) {
      newErrors.startDate = "Start Date must be before End Date.";
      hasError = true;
    }
    if (startDate && startDate.compare(now(getLocalTimeZone())) < 0) {
      newErrors.startDate = "Start Date must be a future date.";
      hasError = true;
    }
    if (startDate && endDate && endDate.compare(startDate) <= 0) {
      newErrors.endDate = "End Date must be after Start Date.";
      hasError = true;
    }

    setErrors(newErrors);
    if (!hasError) {
      handleSubmit();
    }
  };
  const handleSubmit = async () => {
    const formattedStartDate = startDate
      ? formatDate(startDate.toDate(getLocalTimeZone()))
      : "";
    const formattedEndDate = endDate
      ? formatDate(endDate.toDate(getLocalTimeZone()))
      : "";

    const dataObject = {
      name: NAME,
      code: CODE,
      data: {
        message: message,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
      },
    };
    const token = oktaAuth.getAccessToken();
    if (!token) {
      return;
    }
    try {
      if (bannerData) {
        const upDatedResponse = await updateBannerData({
          id: bannerData.id,
          dataObject,
          token,
        })
          .unwrap()
          .then((response) => {
            ToastQueue.positive("The banner is updated successfully", {
              timeout: 5000,
            });
            return response;
          });
        dispatch(setBannerData(upDatedResponse));
      } else {
        await postBannerData({ dataObject, token })
          .unwrap()
          .then((response) => {
            ToastQueue.positive("The banner is saved successfully", {
              timeout: 5000,
            });
            return response;
          });
      }
      onClose();
    } catch (error) {
      ToastQueue.negative("Failed to save the banner", {
        timeout: 5000,
      });
      console.error("Error:", error);
    }
  };
  const getCurrentDateTime = () => {
    return now(getLocalTimeZone());
  };
  useEffect(() => {
    if (bannerData) {
      setMessage(bannerData.data.message);
      const startDateTime = parseAbsoluteToLocal(
        convertToISODateUTCTimeZone(bannerData.data.startDate),
      );
      const endDateTime = parseAbsoluteToLocal(
        convertToISODateUTCTimeZone(bannerData.data.endDate),
      );
      setStartDate(startDateTime);
      setEndDate(endDateTime);
    }
  }, [bannerData]);
  const handleMessageChange = (value: string) => {
    if (value.length >= 100) {
      setErrors({
        ...errors,
        message: "Maximum limit of 100 characters reached",
      });
    } else {
      setErrors({ ...errors, message: "" });
    }
    setMessage(value);
  };

  return (
    <DialogContainer onDismiss={onClose}>
      <Dialog width="464px">
        <View position="absolute" top="0px" right="12px">
          <Button
            variant="secondary"
            onPress={closeModal}
            aria-label="Close"
            UNSAFE_style={{
              padding: 0,
              backgroundColor: "transparent",
              border: "none",
            }}
          >
            <CrossSmall />
          </Button>
        </View>
        <Heading>Banner Details</Heading>
        <Divider />
        <Content>
          <Form validationBehavior="native">
            <Flex direction="column" gap="size-200">
              <TextField
                isRequired
                label="Banner Message"
                value={message}
                onChange={handleMessageChange}
                validationState={errors.message ? "invalid" : undefined}
                width="100%"
                aria-label="Enter your banner message"
                maxLength={100}
                errorMessage={errors.message}
              />
              <DatePicker
                label="Start Date"
                value={startDate}
                granularity="minute"
                onChange={(value) => {
                  setStartDate(value);
                  setErrors({ ...errors, startDate: "" });
                }}
                validationState={errors.startDate ? "invalid" : undefined}
                hourCycle={24}
                hideTimeZone
                isRequired
                errorMessage={errors.startDate}
                minValue={getCurrentDateTime()}
              />
              <DatePicker
                label="End Date"
                value={endDate}
                hourCycle={24}
                granularity="minute"
                onChange={(value) => {
                  setEndDate(value);
                  setErrors({ ...errors, endDate: "" });
                }}
                validationState={errors.endDate ? "invalid" : undefined}
                hideTimeZone
                isRequired
                errorMessage={errors.endDate}
                minValue={startDate || getCurrentDateTime()}
              />
            </Flex>
            <div className="button-group">
              <ButtonGroup UNSAFE_style={{ marginTop: "30px" }}>
                <Button variant="secondary" onPress={closeModal}>
                  Cancel
                </Button>
                <Button variant="accent" onPress={handleFormSubmit}>
                  Submit
                </Button>
              </ButtonGroup>
            </div>
          </Form>
        </Content>
      </Dialog>
    </DialogContainer>
  );
};

export default BannerForm;

export interface AiPrompt {
  label: string;
  prompt: string | null;
}

export const promptMatrix = {
  "investigate-summary-commerce-project": {
    label: "Project Summary",
    prompt:
      "Provide instance summary. Use the following format for your response:\n" +
      "## Project Summary\n" +
      "- **Instance URL:** (Project URL)\n" +
      "- **Adobe Commerce:** (AC value)\n" +
      "- **HIPAA Compliance:** (value)\n" +
      "- **B2B Version:** (value)\n" +
      "- **ECE Version:** (value)\n" +
      "- **Mage ID:** (value)\n" +
      "- **Cluster Type:** (value)\n" +
      "### Services:\n" +
      "- **PHP:** (value) if (PHP EOL Date) print '*Expired on (PHP EOL Date)*'\n" +
      "- **MySQL:** (value) if (MySQL EOL Date) print '*Expired on (MySQL EOL Date)*'\n" +
      "- **Redis:** (value) if (Redis EOL Date) print '*Expired on (Redis EOL Date)*'\n" +
      "- **Elasticsearch:** (value) if (Elasticsearch EOL Date) print '*Expired on (Elasticsearch EOL Date)*'\n",
  },
  "investigate-summary-quick": {
    label: "Quick Summary",
    prompt:
      "Summarize the customer case in the following format:\n" +
      "#### Summarized Description:\n" +
      "text\n" +
      "- **Customer Impact:**\n" +
      "- **Customer Communication:**\n" +
      "- **Sentiment Analysis:**\n" +
      "- **Steps Taken:**\n" +
      "- **Status and Resolution:**\n" +
      "- **Final Outcome:**\n" +
      "Ensure that each item is a block of Markdown text and includes the names of relevant individuals.",
  },
  "investigate-summary-detailed": {
    label: "Detailed Summary",
    prompt:
      "Provide a detailed summary of the customer case, and include relevant links.",
  },
  "investigate-summary-soap": {
    label: "SOAP Summary",
    prompt:
      "Summarize the customer case in the following format:\n" +
      "- **Scope:** (Define the issue/question on the ticket, as specific as possible)\n" +
      "- **Objective:** (Define the expected outcome)\n" +
      "- **Actions:** (Define the specific actions performed by the support engineer and customer)\n" +
      "- **Plan:** (Describe future actions to be done by the support engineer and customer)\n" +
      "- **Business Impact:** (Describe business impact)\n" +
      "- **Current Work Around:** (Describe current work around)\n" +
      "- **Next Steps:** (Describe next steps)\n" +
      "Ensure that each item includes the names of relevant individuals.",
  },
  "investigate-summary-cadence": {
    label: "Cadence Summary",
    prompt:
      "# Detailed Cadence Summary Table Prompt\n" +
        "\n" +
        "## Task\n" +
        "\n" +
        "Create a detailed cadence summary table for a customer case, capturing the sequence of responses, timestamps, delays, and overall **Customer Support Average Response Time**. Ensure accurate identification of the **Longest delay** and **Shortest delay**.\n" +
        "\n" +
        "---\n" +
        "\n" +
        "## Steps\n" +
        "\n" +
        "### 1. Briefly describe the purpose of the Cadence Summary table (e.g. Based on the provided customer case, here is the cadence summary indicating who responded and when, along with where the delay in the response occurred, and time delay between each response)\n" +
        "### 2. Organize Chronologically\n" +
        "- Arrange all responses by their **Date and Time (UTC)** in chronological order.\n" +
        "- Use strict formatting for timestamps: `yyyy-MM-dd HH:mm:ss`.\n" +
        "\n" +
        "### 3. Calculate Time Delay\n" +
        "- Compute the absolute difference in time between each response and the one that immediately preceded it.\n" +
        "- Use the format \"Xd Xh Xm\" for the delay:\n" +
        "  - **X** = Value (e.g., 1, 2, 3).\n" +
        "  - **d** = Days.\n" +
        "  - **h** = Hours.\n" +
        "  - **m** = Minutes.\n" +
        "  - Omit any zero values for better readability (e.g., \"1d 4h\" instead of \"1d 4h 0m\").\n" +
        "\n" +
        "### 4. Calculate Customer Support **Average Response Time**\n" +
        "- Identify all responses made by customer support roles (e.g., Agent, Supervisor).\n" +
        "- Calculate the average time it took for customer support to respond after the preceding message.\n" +
        "- Present the **Customer Support Average Response Time** in the format \"Xd Xh Xm.\"\n" +
        "\n" +
        "### 5. Identify Longest and Shortest Delays\n" +
        "- Compare all calculated delays (excluding the first response with `-` for delay).\n" +
        "- Determine and report:\n" +
        "  - **Longest delay**: The maximum delay in the process.\n" +
        "  - **Shortest delay**: The minimum delay in the process.\n" +
        "\n" +
        "---\n" +
        "\n" +
        "## Output Format\n" +
        "\n" +
        "Provide the summarized information in a markdown-formatted table with the following columns:\n" +
        "\n" +
        "- **Responder**: Name or identifier of the responder.\n" +
        "- **Role**: Indicate the role of the responder (e.g., customer, support agent).\n" +
        "- **Date and Time (UTC)**: Record the time of the response, formatted as `yyyy-MM-dd HH:mm:ss`.\n" +
        "- **Summary**: Briefly describe the context or purpose of the response.\n" +
        "- **Time Delay (Xd Xh Xm)**: Accurately calculate and present the delay since the last response.\n" +
        "\n" +
        "### Example Table\n" +
        "\n" +
        "| **Responder** | **Role**  | **Date and Time (UTC)** | **Summary**                | **Time Delay (Xd Xh Xm)** |\n" +
        "|---------------|-----------|-------------------------|----------------------------|---------------------------|\n" +
        "| Agent         | Support   | 2023-04-15 12:30:45    | Asked for more details     | -                         |\n" +
        "| Customer      | Client    | 2023-04-15 15:47:11    | Provided information       | 0d 3h 16m                 |\n" +
        "| Supervisor    | Manager   | 2023-04-16 09:22:31    | Clarified next steps       | 0d 17h 35m               |\n" +
        "| Customer      | Client    | 2023-04-16 12:10:00    | Confirmed receipt          | 0d 2h 48m                 |\n" +
        "\n" +
        "---\n" +
        "\n" +
        "## Observations\n" +
        "\n" +
        "After generating the table, summarize the following:\n" +
        "\n" +
        "### 1. Average Response Time, Longest and Shortest Delays\n" +
        "- **Average Response Time**: Calculate and report only the final average time the customer waited for a response from support roles (e.g., Agent, Supervisor), omitting detailed calculations.\n" +
        "- **Longest Delay**: Identify and report the maximum delay, including the responders involved.\n" +
        "- **Shortest Delay**: Identify and report the minimum delay, including the responders involved.\n" +
        "\n" +
        "### 2. **Average Response Time**\n" +
        "- Summarize the average response time of the support team (e.g., Agent, Supervisor).\n" +
        "- Include only the delays directly associated with responses from support roles.\n" +
        "\n" +
        "### Example Observations\n" +
        "\n" +
        "- The **Average Response Time** was 8 hours and 25 minutes.\n" +
        "- The **Longest delay** was 17 hours and 35 minutes, occurring between the support agent and supervisor responses.\n" +
        "- The **Shortest delay** was 2 hours and 48 minutes, occurring between the supervisor's reply and the customer's confirmation.\n" +
        "\n" +
        "---\n" +
        "\n" +
        "## Notes\n" +
        "\n" +
        "- Always calculate delays consistently for all rows.\n" +
        "- If delays are equal, mention all ties or clarify tie-breaking logic.\n" +
        "- Clearly distinguish delays to indicate if they result from internal team response times or customer wait times.\n" +
        "- Use the specific delay format (\"Xd Xh Xm\") for uniformity and clarity.\n"
  },
  "suggest-resolution": {
    label: "Suggest Resolution",
    prompt: null,
  },
  "other-knowledge-center": {
    label: "Knowledge Center",
    prompt: null,
  },
  "investigate-custom-prompt": {
    label: "Custom Prompt",
    prompt: null,
  },
  "aem-non-cso-rca": {
    label: "Non-CSO RCA",
    prompt:
      "You are a support engineer that is in charge of making RCA statement describing critical issues (with the software {product}). These issues are sometimes referred to internally as CSOs You will be given details taken from an customer case and" +
      " Jira tickets (take into account Jira tickets comments) and you need to summarize the issue in a way that would make sense to an end user of {product}. Avoid using technical jargon or references to the names of any back end services. The customer statement should include a description of the issue, impacts, timeframe it occurred, a high level description of the resolution, and if applicable any actions required on the customers end. Try not to be overly apologetic and focus mostly on the facts (what happened, why, the scope of the issue, what we are doing going forward, when it happened, etc.). The end result will be a document sent out to customers to describe the issue\n" +
      "Everything below is a style guide for creating RCA statements. Please follow all of the suggestions/formats below\n" +
      "DOs and DONTs\n" +
      " DOs\n" +
      "	DO write with empathy and human tone to avoid using canned responses and sounding unsympathetic.\n" +
      "	DO review grammar and punctuation for correctness.\n" +
      "	DO keep it simple, succinct, and precise.\n" +
      "	DO clearly state the scope (e.g. single data center, all customers, etc.).\n" +
      "	DO focus on customer impact.\n" +
      "	DO provide workarounds when possible.\n" +
      "	DO get formal/written approval by Customer Support Leadership (and get CSO owner input if possible). \n" +
      "	DO run the content of the communication by your legal representative.\n" +
      "	DO write as if the world is reading.\n" +
      "	DO state the facts and acknowledge the issue.\n" +
      "	Tell the customers what happened without speculation. We want to avoid having to re-explain or qualify something we said initially. \n" +
      "	DO write in a way that any customer, at any technical level, can understand.\n" +
      "DONTs\n" +
      "	DON'T say technology names (e.g. network vs. AVI).\n" +
      '	DON\'T use the term "outage", instead, use "service disruption".\n' +
      "	DONT use the term human error.\n" +
      "	DONT use slang.\n" +
      "	DON'T list customer names or provide details for one customer to another customer.\n" +
      "	DON'T use specific technical jargon.\n" +
      "	DON'T state that Adobe has disciplined an employee.\n" +
      "	DON'T admit we did something wrong, or make any reference to SLA, or say something that suggests that an SLA has been breached, or subjects ourselves to contractual liability/warranty claims, e.g. creates additional financial risk/exposure to Adobe.\n" +
      "	DON'T say something factually incorrect (we have a fix when we dont, or vice versa, we say we have a problem and then go back and say we didnt have an issues). \n" +
      "	DON'T promise/commit to do more than we actually plan to do.\n" +
      "	Do not commit to do x/y/z in response to an issue unless we are sure we have the executive support and resources to in fact complete x/y/z.\n" +
      "	The same goes for time commitments. Do not commit to a certain time unless you are ~100% sure we will meet that time. Instead, opt for currently targeting type language, but still be confident in those estimates.\n" +
      "	Specifically, for External Communications:\n" +
      "	DON'T set resolution timing expectations or set expectations in general. The only exception is offering the next communication update if you feel that this would improve the customer's experience.\n" +
      "Common Terms\n" +
      "	Use may or could to make the sentence less definitive. \n" +
      "	Example: During the impacted timeframe, a subset of customers may have experienced delays in list imports as well as smart lists returning inaccurate results. In addition, smart campaigns could have failed to initiate.\n" +
      "	Use reinitialize rather than restart or reset.	Example: Our team reinitialized the server cluster to restore service and resume activity processing.\n" +
      "	Use impacted and affected.\n" +
      "    	Example: During the impacted timeframe, customers may have noticed delays in trigger campaigns as well as smart lists returning inaccurate data. Batch campaigns were not affected by this issue.\n" +
      "	Use intermittent to describe the issue when it is sporadic or inconsistent in nature.\n" +
      "	Example: During the impacted timeframe, customers may have experienced intermittent login errors when attempting to access their instances.\n" +
      "	Use subset when the issue did not affect all customers or services.\n" +
      "	Example: This is a RCA Statement for the Service Degradation that affected a subset of customers in our Ashburn data center on October 5, 2020.\n" +
      "	Avoid using the words down or outage. Instead, use unavailable, inaccessible, or unreachable to describe how an issue affected services/systems.\n" +
      "	Example: An expired license caused several network storage devices to become unavailable.\n" +
      "	Avoid using the word problem.\n" +
      "Non-CSO RCA Guidelines\n" +
      "a. Non-CSO RCA Template\n" +
      "b. Section Details\n" +
      "	#### Case Number - leave blank\n" +
      "	#### Case Summary\n" +
      "	Overarching description of the incident (i.e., {product}  Service Degradation or Adobe Experience Manager Reporting Disruption)\n" +
      "	#### Overview\n" +
      "	The overview paragraph has three core components.\n" +
      "	The opening sentence introduces the incident and when it occurred. \n" +
      "	Only one sentence is needed.\n" +
      "	Use the same term for the problem as used in the Summary Title (disruption/issue/degradation). \n" +
      "	Examples:\n" +
      "	This is the final RCA Statement for the service disruption that affected a subset of customers in our San Jose data center on October 16, 2021.\n" +
      "	This is the final RCA Statement for the service degradation that affected customers in our London data center beginning on September 20, 2021.\n" +
      "	The second sentence should include a description of the customer impact.\n" +
      "	The description of symptoms should be short and concise. Keep it to one to two sentences. \n" +
      "	Examples: \n" +
      "	The Adobe {product} system hosting your subscription experienced a service disruption that impacted all web services.\n" +
      "	The Adobe {product} system hosting your subscription may have encountered an issue that impacted batch campaign processing.\n" +
      "	The final sentence should confirm that the issue has been resolved including the end date, if applicable. \n" +
      "	Examples: \n" +
      "	We identified and mitigated the source of the disruption, and this issue was resolved on December 20, 2021.\n" +
      "	We identified and mitigated the source of the degradation, and the issue has been resolved.\n" +
      "	#### Impact Window\n" +
      "	Format:\n" +
      "	Month Day, Year, Start Time  End Time\n" +
      "	Guidelines:\n" +
      "	Do not use th or nd after the day number. Just give the number alone.\n" +
      "	Use a    dash to indicate the duration between start and end timestamps, not to, through or until. \n" +
      "	Use Coordinated Universal Time (UTC).\n" +
      "	Example:\n" +
      "December 19, 2021, 11:24 UTC  11:49 UTC\n" +
      "	#### Impact Duration\n" +
      "	Give the number of days, hours, and minutes between the start and end times.\n" +
      "	Example:\n" +
      "5 Days, 15 Hours, 30 Minutes\n" +
      "	Service(s) Affected\n" +
      "	Provide a short description of the services that were affected. Keep descriptions high-level and be sure to list the main symptoms that the majority of customers could have experienced. If there is a long list of symptoms, group them into general descriptions. After listing what services were affected, describe what was still operating as expected.\n" +
      "	If all services were affected, that should be stated.\n" +
      "	Example:\n" +
      "During the impacted timeframe, access to all Adobe {product} web services may have been disrupted, including the serving of interactive logins, forms, and landing pages. While these services were impacted, campaign processing and all other back-end systems continued to function as expected.\n" +
      "	#### What Happened & Why\n" +
      "	This section tells the story to the customer including what caused the issue and how our teams remediated it. The cause statement is the main purpose of the document but is one of the shortest sections. Descriptions should be short, limited to a few sentences. Descriptions of the problem and symptoms can go into detail as needed, but most times the cause is very simple. Do not speculate; only list the facts. Write so that customers, at any technical level, can easily understand the impact. The remediation steps should describe what we did to fix the immediate issue.\n" +
      "	Resolution:\n" +
      "* This section should never be more than three sentences.\n" +
      "	Example:\n" +
      "Due to a misconfiguration, the memory capacity on the primary database was lower than the standard for other databases. This caused the database to reach the maximum number of available connections and prevented new connections from being processed. A replication delay prevented the team from immediately completing a primary switch, which prolonged the time to resolution.\n" +
      "The Adobe {product} throttled queries on the primary database to accelerate the rate of replication, then performed the primary database switch to restore service.\n" +
      "	#### Plans to Prevent Reoccurrence\n" +
      "	This section should outline:\n" +
      "	What is being done to maintain system stability in the short term?\n" +
      "	What changes are being made to prevent future occurrences (if available)? And, again, avoid over-committing; describe only what we are certain will be done and have executive support to ensure. \n" +
      "	Example:\n" +
      "	Adobe is taking the following steps to help prevent this event from reoccurring and to mitigate future potential impact:\n" +
      "	Increasing memory capacity on the affected database to ensure it meets the configuration standard.\n" +
      "	Updating the query execution logic to enhance database performance.\n",
  },
} satisfies Record<string, AiPrompt>;

export type PromptMatrixKey = keyof typeof promptMatrix;

export const suggestionsKeys: PromptMatrixKey[] = [
  "investigate-summary-commerce-project",
  "investigate-summary-quick",
  "investigate-summary-detailed",
  "investigate-summary-soap",
  "investigate-summary-cadence",
  "aem-non-cso-rca",
];

import React from "react";
import { ComboBox, Item, Button } from "@adobe/react-spectrum";
import CheckmarkCircle from "@spectrum-icons/workflow/CheckmarkCircle";
import { useAccountDetails } from "../../hooks/useAccountDetails";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import CrossSmall from "@spectrum-icons/ui/CrossSmall";

interface CommonFilterProps {
  onFilterChange: (filters: {
    productFamilyFilter: string | null;
    projectFilter: string | null;
    environmentFilter: string | null;
  }) => void;
}

const CommonFilter: React.FC<CommonFilterProps> = ({ onFilterChange }) => {
  const orgId = useSelector((state: RootState) => state.case.caseObject?.orgId);
  const { productFamilies, isProductFamiliesLoading, dynamicData } =
    useAccountDetails(orgId);

  const [productFamilyFilter, setProductFamilyFilter] = React.useState<
    string | null
  >(null);
  const [projectFilter, setProjectFilter] = React.useState<string | null>(null);
  const [environmentFilter, setEnvironmentFilter] = React.useState<
    string | null
  >(null);

  const onProductFamilyChange = (key: React.Key | null) => {
    const selectedItem = dynamicData?.find(
      (item) => item.productFamilyName === key,
    );

    const selectedName = selectedItem ? selectedItem.productFamilyName : null;
    setProductFamilyFilter(selectedName);
    onFilterChange({
      productFamilyFilter: selectedName,
      projectFilter,
      environmentFilter,
    });
  };

  const onProjectChange = (key: React.Key | null) => {
    const selectedItem = dynamicData?.find((item) => item.projectName === key);
    const selectedName = selectedItem ? selectedItem.projectName : null;
    setProjectFilter(selectedName);
    onFilterChange({
      productFamilyFilter,
      projectFilter: selectedName,
      environmentFilter,
    });
  };

  const onEnvironmentChange = (key: React.Key | null) => {
    const selectedItem = dynamicData?.find(
      (item) => item.environmentName === key,
    );
    const selectedName = selectedItem ? selectedItem.environmentName : null;
    setEnvironmentFilter(selectedName);
    onFilterChange({
      productFamilyFilter,
      projectFilter,
      environmentFilter: selectedName,
    });
  };

  const onClearProductFamily = () => {
    setProductFamilyFilter(null);
    onFilterChange({
      productFamilyFilter: null,
      projectFilter,
      environmentFilter,
    });
  };

  const onClearProject = () => {
    setProjectFilter(null);
    onFilterChange({
      productFamilyFilter,
      projectFilter: null,
      environmentFilter,
    });
  };

  const onClearEnvironment = () => {
    setEnvironmentFilter(null);
    onFilterChange({
      productFamilyFilter,
      projectFilter,
      environmentFilter: null,
    });
  };
  const onClearAll = () => {
    setProductFamilyFilter(null);
    setProjectFilter(null);
    setEnvironmentFilter(null);
    onFilterChange({
      productFamilyFilter: null,
      projectFilter: null,
      environmentFilter: null,
    });
  };

  if (isProductFamiliesLoading) {
    return <p>Loading product families...</p>;
  }
  const productFamilieNames = Array.from(
    new Set(dynamicData?.map((item) => item.productFamilyName) || []),
  );
  const projectNames = Array.from(
    new Set(dynamicData?.map((item) => item.projectName) || []),
  );
  const environmentNames = Array.from(
    new Set(dynamicData?.map((item) => item.environmentName) || []),
  );
  const selectedFilterCount = [
    productFamilyFilter,
    projectFilter,
    environmentFilter,
  ].filter(Boolean).length;
  return (
    <>
      <div className="filter-container">
        <p className="filter-label">FILTER APPLIED ON :</p>

        <ComboBox
          placeholder="Solution"
          defaultItems={productFamilieNames.map((name) => ({ id: name, name }))}
          selectedKey={productFamilyFilter}
          onSelectionChange={onProductFamilyChange}
          UNSAFE_className="marginRight custom-combo-box"
        >
          {(item) => <Item key={item.id}>{item.name}</Item>}
        </ComboBox>

        <ComboBox
          placeholder="Provisioned Instance"
          defaultItems={projectNames.map((name) => ({ id: name, name }))}
          selectedKey={projectFilter}
          onSelectionChange={onProjectChange}
          UNSAFE_className="marginRight"
        >
          {(item) => <Item key={item.id}>{item.name}</Item>}
        </ComboBox>

        <ComboBox
          placeholder="Environment"
          defaultItems={environmentNames.map((name) => ({ id: name, name }))}
          selectedKey={environmentFilter}
          onSelectionChange={onEnvironmentChange}
        >
          {(item) => <Item key={item.id}>{item.name}</Item>}
        </ComboBox>
      </div>

      <div className="filter-list-container">
        {productFamilyFilter && (
          <div className="selected-filter-container">
            <CheckmarkCircle UNSAFE_className="filter-checkmark" />
            <span className="selected-filter">{productFamilyFilter}</span>
            <Button
              variant="secondary"
              aria-label="Clear Product Family Filter"
              UNSAFE_style={{
                padding: 0,
                backgroundColor: "transparent",
                border: "none",
                color: "#0265DC",
              }}
              onPress={onClearProductFamily}
            >
              <CrossSmall />
            </Button>
          </div>
        )}

        {projectFilter && (
          <div className="selected-filter-container">
            <CheckmarkCircle UNSAFE_className="filter-checkmark" />
            <span className="selected-filter">{projectFilter}</span>
            <Button
              variant="secondary"
              aria-label="Clear Project Filter"
              UNSAFE_style={{
                padding: 0,
                backgroundColor: "transparent",
                border: "none",
                color: "#0265DC",
              }}
              onPress={onClearProject}
            >
              <CrossSmall />
            </Button>
          </div>
        )}

        {environmentFilter && (
          <div className="selected-filter-container">
            <CheckmarkCircle UNSAFE_className="filter-checkmark" />
            <span className="selected-filter">{environmentFilter}</span>
            <Button
              variant="secondary"
              aria-label="Clear Environment Filter"
              UNSAFE_style={{
                padding: 0,
                backgroundColor: "transparent",
                border: "none",
                color: "#0265DC",
              }}
              onPress={onClearEnvironment}
            >
              <CrossSmall />
            </Button>
          </div>
        )}
        {selectedFilterCount > 1 && (
          <Button
            variant="secondary"
            aria-label="Clear All Filters"
            UNSAFE_style={{
              padding: "0px",
              margin: "0px",
              backgroundColor: "transparent",
              color: "#0265DC",
              border: "none",
              fontSize: "12px",
              verticalAlign: "middle",
            }}
            onPress={onClearAll}
          >
            Clear All
          </Button>
        )}
      </div>
    </>
  );
};

export default CommonFilter;

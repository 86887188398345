import React from "react";
import { View, Flex, Well, ProgressCircle } from "@adobe/react-spectrum";
import CfsGenerator from "./CfsGenerator";
import CfStatement from "./CfStatement";
import { useGenerateCfs } from "../../../hooks/cfsTool/useGenerateCfs";

const CFSPage: React.FC = () => {
  const [cfsState, _] = useGenerateCfs();

  const hasResponse = cfsState.aiSummary.text !== "";
  // loading summary
  const loading = (
    <View paddingTop={"size-500"}>
      <Flex direction="column">
        <ProgressCircle aria-label="Loading…" isIndeterminate />
      </Flex>
    </View>
  );

  return (
    <>
      <CfsGenerator />
      {cfsState.aiSummary.isLoading && !cfsState.aiSummary?.text && loading}
      {hasResponse && (
        <View paddingTop={10}>
          <Well>
            <CfStatement />
          </Well>
        </View>
      )}
    </>
  );
};

export default CFSPage;

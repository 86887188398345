import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import MenuConfig from "../config/MenuConfig";
import AiAssistantPage from "../pages/AiAssistant";
import { useOktaAuth } from "@okta/okta-react";
import BaseLayout from "../layouts/BaseLayout";
import { RequiredAuth } from "../components/common/RequiredAuth";
import CustomLoginCallback from "../components/common/CustomLoginCallback";
import ErrorPage from "../pages/ErrorPage";
import { useDispatch } from "react-redux";
import { clearUser, setUser } from "../store/userSlice";
import MinimalLayout from "../layouts/MinimalLayout";
import NotFoundPage from "../pages/NotFoundPage";
import { addToDataLayer } from "../utils/analytics";
import SearchPage from "../pages/SearchPage";
import CFSPage from "../pages/CFSPage";
import { setUserRole } from "../store/userRoleSlice";
import { useGetUserRoleQuery } from "../services/supportInsights";

const internalSideBarRoutes = () => {
  const internalLinks = MenuConfig.filter(
    (item) => item.LinkType === "Internal",
  );
  return internalLinks.map((item) => (
    <Route
      key={item.LinkName}
      path={item.LinkURL.replace(
        ":caseInfoSuffix",
        "case/:caseId/org_id/:orgId/renga_id/:rengaId",
      )}
      element={item.component}
    />
  ));
};

const AppRoutes: React.FC = () => {
  // const navigate = useNavigate();
  const { oktaAuth, authState } = useOktaAuth();
  const dispatch = useDispatch();
  const token: string | undefined = oktaAuth.getAccessToken();
  const skip = !token;
  const { data, error, isLoading } = useGetUserRoleQuery(token as string, {
    skip,
  });
  useEffect(() => {
    if (!authState?.isAuthenticated) {
    } else {
      oktaAuth.token
        .getUserInfo()
        .then((userInfo) => {
          dispatch(setUser(userInfo));
          addToDataLayer({ userInfo });
          //  navigate('/'); // Redirect to a dashboard or home page
        })
        .catch(() => dispatch(clearUser()));
    }
  }, [oktaAuth, authState, authState?.isAuthenticated, dispatch]);
  useEffect(() => {
    if (token && data) {
      dispatch(setUserRole(data.role));
    }
  }, [data, error, token, dispatch]);
  return (
    <Routes>
      <Route path="/" element={<BaseLayout />}>
        <Route path="/" element={<RequiredAuth />}>
          <Route path="/" element={<SearchPage />} />
          <Route path="/cfs/:jiraTicket?" element={<CFSPage />} />
          <Route path="/search/:caseIdOrOrgId?" element={<SearchPage />} />
          <Route path="review/case/:caseId" element={<AiAssistantPage />} />
          {internalSideBarRoutes()}
        </Route>
      </Route>
      <Route
        path="/Error"
        element={
          <MinimalLayout>
            <ErrorPage />
          </MinimalLayout>
        }
      ></Route>
      <Route
        path="/NotFound"
        element={
          <MinimalLayout>
            <NotFoundPage />
          </MinimalLayout>
        }
      ></Route>

      <Route path="/login/callback" element={<CustomLoginCallback />} />
      <Route
        path="*"
        element={
          <MinimalLayout>
            <NotFoundPage />
          </MinimalLayout>
        }
      />
    </Routes>
  );
};

export default AppRoutes;

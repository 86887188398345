import { Dispatch } from "redux";
import { setCase, setIsTicketRefreshing } from "../store/case/caseSlice";
import * as analytics from "../utils/analytics";

interface HandleRefreshParams {
  caseId: string;
  orgId: string;
  rengaId: string;
  productFamily: string;
  refreshTicketData: any;
  setIsDisabled: (disabled: boolean) => void;
  dispatch: Dispatch;
}

export const handleRefresh = async ({
  caseId,
  orgId,
  rengaId,
  productFamily,
  refreshTicketData,
  setIsDisabled,
  dispatch,
}: HandleRefreshParams) => {
  setIsDisabled(true);
  dispatch(setIsTicketRefreshing(true));
  analytics.track("refreshButtonClicked", {
    caseId: caseId,
    solution: productFamily,
    orgId: orgId,
  });
  try {
    const refreshResponse = await refreshTicketData({
      caseId: caseId,
      orgId: orgId,
      rengaId: rengaId,
    }).unwrap();
    dispatch(setCase(refreshResponse.data));
    dispatch(setIsTicketRefreshing(false));
    return refreshResponse.data;
  } catch (error) {
    setIsDisabled(false);
    dispatch(setIsTicketRefreshing(false));
  }
};

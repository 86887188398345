import React from "react";
import { Divider, View, Flex } from "@adobe/react-spectrum";

interface HomePageCardTableRowProps {
  children: React.ReactNode;
}

const HomePageCardTableRow: React.FC<HomePageCardTableRowProps> = ({
  children,
}) => {
  return (
    <View width="100%">
      <Flex direction="row" gap="size-250">
        {children}
      </Flex>
      <Divider size="S" marginY="size-150" />
    </View>
  );
};

export default HomePageCardTableRow;

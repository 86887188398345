import React from "react";

interface SelectableTableCellProps {
  children: React.ReactNode;
}

/**
 * This component is used to wrap content for a table cell that can be selected and copied by a user.
 * @param {React.ReactNode} children - The content to be wrapped.
 * @returns {React.ReactElement} A span element with the content that can be selected and copied.
 */
const SelectableTableCellContent: React.FC<SelectableTableCellProps> = ({
  children,
}) => {
  return (
    <span
      style={{ cursor: "text", WebkitUserSelect: "text" }}
      onPointerDown={(e) => e.stopPropagation()}
      onMouseDown={(e) => e.stopPropagation()}
    >
      {children}
    </span>
  );
};

export default SelectableTableCellContent;

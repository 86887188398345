import React from "react";
import HomePageCardTableRow from "./HomePageCardTableRow";
import {
  GetTicketWatchlistResponse,
  WatchlistTicketItem,
} from "../../types/watchlist";
import TableRowKeyValuePair from "./TableRowKeyValuePair";

interface UserWatchlistTableProps {
  data: GetTicketWatchlistResponse;
}

const UserTicketWatchlistTable: React.FC<UserWatchlistTableProps> = ({
  data,
}) => {
  const generateTicketUrl = (ticket: WatchlistTicketItem["item"]): string => {
    return `${window.location.origin}/review/case/${ticket.caseId}/org_id/${ticket.orgId}/renga_id/${ticket.admin.rengaId}`;
  };

  return (
    <>
      {data.data.map((ticket) => (
        <HomePageCardTableRow>
          <TableRowKeyValuePair
            title="Case #"
            value={ticket.item.caseId}
            elementWidth="20%"
            valueType="link"
            linkHref={generateTicketUrl(ticket.item)}
          />
          <TableRowKeyValuePair
            title="Org Name"
            value={ticket.item.orgName || "Unknown"}
            elementWidth="30%"
          />
          <TableRowKeyValuePair
            title="Solution"
            value={ticket.item.productFamilyName}
            elementWidth="25%"
          />
          <TableRowKeyValuePair
            title="Status"
            value={ticket.item.statusText}
            elementWidth="15%"
          />
        </HomePageCardTableRow>
      ))}
    </>
  );
};

export default UserTicketWatchlistTable;

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  useGetTicketByIdQuery,
  useGetTicketByFullInfoQuery,
  useRefreshTicketMutation,
  useDynamicsCollectDataMutation,
} from "../services/supportInsights";
import {
  setCase,
  setOrgId,
  setRengaId,
  setTicketId,
  setTicketDbId,
} from "../store/case/caseSlice";
import { RootState } from "../store/store";
import { handleRefresh } from "../utils/handleRefresh";
import { collectDynamicsData } from "../utils/collectDynamicsData";
import familyNameToCode from "../utils/familyNameToCode";

const useCaseData = () => {
  const caseObject = useSelector((state: RootState) => state.case.caseObject);
  const caseIdState = useSelector((state: RootState) => state.case.ticketId);
  const orgIdState = useSelector((state: RootState) => state.case.orgId);
  const rengaIdState = useSelector((state: RootState) => state.case.rengaId);
  const dispatch = useDispatch();
  const { caseId, orgId, rengaId } = useParams();
  const [, setIsDisabled] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [forceTicketDataReload, setForceTicketDataReload] = useState(false);
  const [forceRecollect, setForceRecollect] = useState(false);
  const [refreshError, setRefreshError] = useState({});
  const [doneLoading, setDoneLoading] = useState(false);

  const [refreshTicketData] = useRefreshTicketMutation();
  const productFamily = caseObject?.productFamilyName ?? "";
  const productFamilyCode = familyNameToCode(productFamily);

  const validCaseId = caseIdState || caseId || "";
  const validOrgId = orgIdState || orgId || "";
  const validRengaId = rengaIdState || rengaId || "";
  const shouldUseFullInfoQuery =
    validCaseId && validOrgId && validRengaId && !caseObject;
  const shouldUseByIdQuery =
    validCaseId && !caseObject && !shouldUseFullInfoQuery;

  const [dynamicsCollectData, { isLoading: dynamicsIsLoading }] =
    useDynamicsCollectDataMutation();

  const fullInfoQueryResult = useGetTicketByFullInfoQuery(
    { ticketId: validCaseId, orgId: validOrgId, rengaId: validRengaId },
    {
      skip: !shouldUseFullInfoQuery,
    },
  );

  const byIdQueryResult = useGetTicketByIdQuery(
    { ticketId: validCaseId },
    {
      skip: !shouldUseByIdQuery,
    },
  );
  const queryResult = shouldUseFullInfoQuery
    ? fullInfoQueryResult
    : byIdQueryResult;

  const { data, error, isLoading } = queryResult;

  useEffect(() => {
    if (error !== undefined && "status" in error && error.status !== 200) {
      setForceTicketDataReload(true);
    }
  }, [error]);

  useEffect(() => {
    if (data?.meta?.ticket_updated_at) {
      const currentDate = new Date();
      const ticketUpdatedAt = new Date(data.meta.ticket_updated_at * 1000);
      const diffInTime = currentDate.getTime() - ticketUpdatedAt.getTime();
      const diffInDays = Math.round(diffInTime / (1000 * 3600 * 24));
      if (diffInDays > 1) {
        setForceTicketDataReload(true);
      } else {
        setDoneLoading(true);
      }
    } else {
      setDoneLoading(true);
    }
  }, [data]);

  useEffect(() => {
    if (forceTicketDataReload && !isRefreshing) {
      setIsRefreshing(true);
      handleRefresh({
        caseId: validCaseId,
        orgId: validOrgId,
        rengaId: validRengaId,
        productFamily: caseObject?.productFamilyName ?? "",
        refreshTicketData,
        setIsDisabled,
        dispatch,
      })
        .catch((error) => {
          setRefreshError(error);
        })
        .finally(() => {
          setIsRefreshing(false);
          setForceTicketDataReload(false);
          setForceRecollect(true);
          setDoneLoading(true);
        });
    }
  }, [forceTicketDataReload, caseObject]);

  useEffect(() => {
    if (caseObject !== undefined && forceRecollect) {
      collectDynamicsData({
        caseObject,
        dynamicsCollectData,
        productFamilyCode,
        dispatch,
        setIsDisabled,
        silent: true,
      });
    }
  }, [
    caseObject,
    dispatch,
    dynamicsCollectData,
    forceTicketDataReload,
    productFamilyCode,
  ]);

  useEffect(() => {
    if (caseId && !caseIdState) {
      dispatch(setTicketId(caseId));
    }
    if (orgId && !orgIdState) {
      dispatch(setOrgId(orgId));
    }
    if (rengaId && !rengaIdState) {
      dispatch(setRengaId(rengaId));
    }
    if (!caseObject && !isLoading && !error && data?.data) {
      dispatch(setCase(data.data));
      dispatch(setTicketDbId(data.meta.ticket_id));
    }
  }, [
    caseId,
    caseIdState,
    caseObject,
    data,
    error,
    isLoading,
    dispatch,
    orgId,
    orgIdState,
    rengaId,
    rengaIdState,
    shouldUseFullInfoQuery,
  ]);

  return {
    caseObject,
    refreshError,
    isLoading,
    caseId,
    caseResponseData: data,
    forceTicketDataReload,
    doneLoading,
  };
};

export default useCaseData;

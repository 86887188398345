export function convertToLocalTimeDate(date: string): string {
  if (date === "") return "";
  const formatter = new Intl.DateTimeFormat(["en"], {
    month: "short",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    // timeZone: "UTC",
  });
  return formatter.format(new Date(convertToISODateUTCTimeZone(date)));
}

export function convertToISODateUTCTimeZone(date: string): string {
  return date.replace(" ", "T") + "Z";
}

export function getDate(inputString: string) {
  const date = new Date(inputString);
  const year = date.getFullYear().toString().slice(-2);
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  return `${month}/${day}/${year}`;
}

import { createSlice } from "@reduxjs/toolkit";
import type { CustomUserClaims, UserClaims } from "@okta/okta-auth-js";
import { UserPreferences } from "../types/UserPreferences";
import { ProductFamily } from "../types/ProductFamily";

interface UserSlice {
  userDetails: UserClaims<CustomUserClaims> | null;
  error: string | null;
  preferences?: UserPreferences<ProductFamily>;
  openPreferencesModal: boolean;
}

const initialState: UserSlice = {
  userDetails: null,
  error: null,
  openPreferencesModal: false,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.userDetails = action.payload;
      state.error = null;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    clearUser: (state) => {
      state.userDetails = null;
      state.error = null;
    },
    setUserPreferences: (state, action) => {
      state.preferences = action.payload;
    },
    setOpenPreferencesModal: (state, action) => {
      state.openPreferencesModal = action.payload;
    },
  },
});

export const {
  setUser,
  setError,
  clearUser,
  setUserPreferences,
  setOpenPreferencesModal,
} = userSlice.actions;

export default userSlice.reducer;

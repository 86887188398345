import React from "react";
import { View, Flex, Heading } from "@adobe/react-spectrum";
import CfsTool from "../components/cfsTool/CfsTool/CfsTool";
import ContributionComponent from "../components/common/ContributionComponent";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";

const CFSPage: React.FC = () => {
  const userRole = useSelector((state: RootState) => state.userRole.userRole);

  if (userRole !== "prt" && userRole !== "admin") {
    return <></>;
  }

  return (
    <ContributionComponent
      title="Customer Facing Statement Generator"
      developerEmail="zspencer@adobe.com"
    >
      <Flex direction="column">
        <View>
          <Heading level={4}>Select Solution and Jira Tickets</Heading>
          <CfsTool />
        </View>
      </Flex>
    </ContributionComponent>
  );
};

export default CFSPage;

import React, { useState } from "react";
import {
  ActionBarContainer,
  Cell,
  Column,
  Row,
  TableBody,
  TableHeader,
  TableView,
} from "@adobe/react-spectrum";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { useAccountDetails } from "../../hooks/useAccountDetails";
import CommonFilter from "../common/CommonFilter";
import MessageComponent from "../common/MessageComponent";
import SelectableTableCellContent from "../common/SelectableTableCellContent";

const AccountDetailsTable = () => {
  const orgId = useSelector((state: RootState) => state.case.caseObject?.orgId);

  const {
    isOrgLoading,
    isProductFamiliesLoading,
    isProjectsLoading,
    isOrgError,
    dynamicData,
  } = useAccountDetails(orgId);

  const [filters, setFilters] = useState<{
    productFamilyFilter: string | null;
    projectFilter: string | null;
    environmentFilter: string | null;
  }>({
    productFamilyFilter: null,
    projectFilter: null,
    environmentFilter: null,
  });

  const handleFilterChange = (newFilters: {
    productFamilyFilter: string | null;
    projectFilter: string | null;
    environmentFilter: string | null;
  }) => {
    setFilters(newFilters);
  };

  // Filter the dynamicData based on all three filter values independently
  const filteredData = dynamicData.filter((row) => {
    const matchesProductFamily = filters.productFamilyFilter
      ? row.productFamilyName === filters.productFamilyFilter
      : true;
    const matchesProject = filters.projectFilter
      ? row.projectName === filters.projectFilter
      : true;
    const matchesEnvironment = filters.environmentFilter
      ? row.environmentName === filters.environmentFilter
      : true;
    return matchesProductFamily && matchesProject && matchesEnvironment;
  });

  if (isOrgLoading || isProductFamiliesLoading || isProjectsLoading) {
    return <p>Loading...</p>;
  }

  if (isOrgError) {
    return <p>Error fetching organization data.</p>;
  }

  return (
    <div>
      <ActionBarContainer>
        <CommonFilter onFilterChange={handleFilterChange} />
        <TableView
          aria-label="Data points table"
          UNSAFE_className="table-container"
        >
          <TableHeader>
            <Column width="1fr">Solution</Column>
            <Column width="2fr">Provisioned Instance (Project, Program)</Column>
            <Column width="2fr">Environment (Host)</Column>
          </TableHeader>
          <TableBody>
            {filteredData.length > 0 ? (
              filteredData.map((row, index) => (
                <Row key={index}>
                  <Cell>
                    <SelectableTableCellContent>
                      {row.productFamilyName}
                    </SelectableTableCellContent>
                  </Cell>
                  <Cell>
                    <SelectableTableCellContent>
                      {row.projectName}
                    </SelectableTableCellContent>
                  </Cell>
                  <Cell>
                    <SelectableTableCellContent>
                      {row.environmentName}
                    </SelectableTableCellContent>
                  </Cell>
                </Row>
              ))
            ) : (
              <Row>
                <Cell>
                  <MessageComponent title="No data available">
                    No data available
                  </MessageComponent>
                  {/* <span style={{ display: "block", width: "100%" }}>
                    No data available
                  </span> */}
                </Cell>
                <Cell>{""}</Cell>
                <Cell>{""}</Cell>
              </Row>
            )}
          </TableBody>
        </TableView>
      </ActionBarContainer>
    </div>
  );
};

export default AccountDetailsTable;

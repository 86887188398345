import React from "react";
import HomePageCard from "./HomePageCard";
import { useGetUserWatchlistQuery } from "../../services/supportInsights";
import { Flex } from "@adobe/react-spectrum";
import UserTicketWatchlistTable from "./UserTicketWatchlistTable";
import MessageComponent from "../common/MessageComponent";

const UserTicketWatchlistCard: React.FC = () => {
  const { data, isLoading, error } = useGetUserWatchlistQuery({
    itemType: "ticket",
  });

  if (isLoading) {
    return <HomePageCard isLoading={true} />;
  }

  if (data && data.data.length > 0) {
    return (
      <HomePageCard title="Case Watchlist" count={data.meta.item_count}>
        <UserTicketWatchlistTable data={data} />
      </HomePageCard>
    );
  } else if (data && data.data.length === 0) {
    return (
      <HomePageCard title="Case Watchlist">
        <Flex
          direction="column"
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <p className="user-page-table-row-title">Empty Case Watchlist</p>
          <p className="user-page-table-row-value">
            Add cases to your watchlist to view them here
          </p>
        </Flex>
      </HomePageCard>
    );
  }

  if (error) {
    return (
      <HomePageCard>
        <Flex justifyContent="center" alignItems="center" height="100%">
          <MessageComponent title={""} isLoading={false}>
            There was an error fetching your case watchlist. Please try again
            later.
          </MessageComponent>
        </Flex>
      </HomePageCard>
    );
  }

  return null;
};

export default UserTicketWatchlistCard;

import React from "react";
import { Flex } from "@adobe/react-spectrum";

interface TableRowKeyValuePairBase {
  title: string;
  value: string;
  elementWidth: string; // Requires width percentage in string format
}

interface TableRowKeyValuePairText extends TableRowKeyValuePairBase {
  valueType?: "text"; // Default to "text" if not specified
  linkHref?: never; // Prevents linkHref if valueType is "text"
}

interface TableRowKeyValuePairLink extends TableRowKeyValuePairBase {
  valueType: "link";
  linkHref: string; // Requires linkHref if valueType is "link"
}

type TableRowKeyValuePairProps =
  | TableRowKeyValuePairText
  | TableRowKeyValuePairLink;

const TableRowKeyValuePair: React.FC<TableRowKeyValuePairProps> = ({
  title,
  value,
  valueType = "text",
  linkHref,
  elementWidth,
}) => {
  return (
    <Flex direction="column" gap="size-50" width={elementWidth}>
      <p className="user-page-table-row-title">{title}</p>
      {valueType === "text" && (
        <p className="user-page-table-row-value">{value}</p>
      )}
      {valueType === "link" && (
        <a className="user-page-table-row-link" href={linkHref} target="_blank">
          {value}
        </a>
      )}
    </Flex>
  );
};

export default TableRowKeyValuePair;

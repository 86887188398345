import React from "react";
import { View } from "@adobe/react-spectrum";
import { useSelector } from "react-redux";
import UserHomePage from "./UserHomePage";
import SearchResultTable from "../components/search/SearchResultTable";
import { RootState } from "../store/store";
import "../styles/Pages/SearchPage.scss";
import DefaultHomePage from "./DefaultHomePage";

const SearchPage = () => {
  const searchText = useSelector((state: RootState) => state.case.searchText);
  const isUserPersonaFeatureActive =
    localStorage.getItem("userPersonaFeatureActive") === "true";

  const renderContent = () => {
    if (searchText) {
      return (
        <View paddingBottom={55}>
          <SearchResultTable searchText={searchText} />
        </View>
      );
    }
    return isUserPersonaFeatureActive ? <UserHomePage /> : <DefaultHomePage />;
  };

  return <View UNSAFE_className="search_wrapper">{renderContent()}</View>;
};

export default SearchPage;

import React, { Key, useEffect } from "react";
import {
  View,
  Flex,
  ProgressCircle,
  ActionGroup,
  Item,
} from "@adobe/react-spectrum";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { useGetFindingsQuery } from "../../../services/supportInsights";
import familyNameToCode from "../../../utils/familyNameToCode";
import Refresh from "@spectrum-icons/workflow/Refresh";
import { setAiSuggestions } from "../../../store/case/caseSlice";
import { fetchAIResponseStream } from "../../../services/requestServices";
import AiMarkdownRenderer from "../AiMarkdownRenderer";

function useAiSuggestions() {
  const dispatch = useDispatch();
  const aiSuggestions = useSelector(
    (state: RootState) => state.case.aiSuggestions,
  );

  const caseObject = useSelector((state: RootState) => state.case.caseObject);
  const caseId = caseObject?.caseId || "";
  const productFamily = caseObject?.productFamilyName || "";
  const productFamilyCode = familyNameToCode(productFamily);

  const { data, error, isLoading } = useGetFindingsQuery(
    {
      caseId: caseId,
      productFamily: productFamilyCode,
    },
    {
      skip: !caseId || !productFamily,
    },
  );

  const reload = () => {
    dispatch(setAiSuggestions({ text: "", isLoading: false }));
  };

  const token = useSelector((state: RootState) => state.auth.token);

  useEffect(() => {
    if (
      !isLoading &&
      aiSuggestions &&
      !aiSuggestions?.isLoading &&
      !aiSuggestions?.text
    ) {
      const requestBody = {
        caseId,
        promptData: { "oasis-findings": error ? [] : data?.data },
      };
      const url = `${window.env.REACT_APP_API_BASE}dynamics/${caseId}/aiSuggestions`;
      dispatch(setAiSuggestions({ text: "", isLoading: true }));
      fetchAIResponseStream(url, token, requestBody, (response) => {
        dispatch(setAiSuggestions(response));
      }).then((obj) => {
        if (obj?.error) {
          dispatch(
            setAiSuggestions({
              text: "Sorry, but AI suggestions are not available at the moment.",
              isLoading: false,
            }),
          );
        }
      });
    }
  }, [aiSuggestions, caseId, data, error, dispatch, isLoading]);

  return [aiSuggestions, reload] as const;
}

const AiSuggestions: React.FC = () => {
  const [aiSuggestions, reload] = useAiSuggestions();

  return (
    <View>
      <Flex direction="column">
        <View UNSAFE_className="response analytics-generate-ai-suggestions-response">
          <div id={"ai-response"}>
            <AiMarkdownRenderer text={aiSuggestions?.text || ""} />
          </div>
          {(aiSuggestions?.isLoading || !aiSuggestions?.text) && (
            <ProgressCircle aria-label="Loading…" isIndeterminate />
          )}
          <Flex justifyContent="start">
            <ActionGroup
              density="compact"
              isQuiet={true}
              onAction={(key: Key) => (key === "regenerate" ? reload() : null)}
            >
              <Item key="regenerate">
                <Refresh
                  UNSAFE_className="analytics-track-me"
                  data-analytics-link-name="Generate Suggestions"
                  data-analytics-view-name="AI Suggestions"
                />
              </Item>
            </ActionGroup>
          </Flex>
        </View>
      </Flex>
    </View>
  );
};

export default AiSuggestions;

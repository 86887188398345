import {
  View,
  Flex,
  ContextualHelp,
  Content,
  Heading,
  Link,
  DialogTrigger,
} from "@adobe/react-spectrum";
import { ReactNode, useState } from "react";
import AccountDetailsModal from "./AccountDetailsModal";

interface BannerElementProps {
  title: ReactNode;
  value: ReactNode;
  help?: JSX.Element;
  size?: number;
  wideElementClass?: string;
  analyticCaseFieldClass?: string;
}

export const TicketInfoBannerElement: React.FC<BannerElementProps> = ({
  title,
  value,
  help,
  size,
  wideElementClass,
  analyticCaseFieldClass,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <View paddingX="size-200" UNSAFE_style={{ flex: size }}>
      <Flex
        direction="column"
        minWidth="size-1500"
        height="max-content"
        gap="size-50"
        flex="1"
      >
        <div className="ticket-info__header">
          <span className="ticket-info__section-title">{title}</span>
          {help && (
            <ContextualHelp
              variant="help"
              alignSelf={"flex-end"}
              placement="right top"
              UNSAFE_className="ticket-info__ContextualHelp"
            >
              <Heading>{title}</Heading>
              <Content>{help}</Content>
            </ContextualHelp>
          )}
        </div>
        <Flex
          UNSAFE_className={`ticket-info__data ${wideElementClass} ${analyticCaseFieldClass}`}
          alignItems={"center"}
        >
          {value}
        </Flex>
        {title === "Org ID" ? (
          <DialogTrigger>
            <Link>View Account Details</Link>
            {(close) => <AccountDetailsModal onClose={close} />}
          </DialogTrigger>
        ) : null}
      </Flex>
    </View>
  );
};

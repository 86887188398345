import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  ComboBox,
  Form,
  Item,
  TextField,
  ButtonGroup,
  Button,
  TextArea,
  Flex,
  Divider,
  Picker,
  IllustratedMessage,
  Heading,
  Content,
  ProgressCircle,
  View,
} from "@adobe/react-spectrum";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { ToastQueue } from "@react-spectrum/toast";
import ContributionComponent from "../common/ContributionComponent";
import { useNavigate } from "react-router-dom";
import DefectConfirmation from "./DefectConfirmation";
import { FormData } from "./types";
import Unavailable from "@spectrum-icons/workflow/DataUnavailable";

//Webhook URL
const webhookURL =
  "https://hook.app.workfrontfusion.com/p7i181yxax3tgo5842zb6ppde2p1wc2l";

const DefectForm: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [loadingError, setLoadingError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Used to control re-rendering API Calls to Fusion/Hub
  const componentMounted = useRef(false);

  // Defines pre-populate template for the description field
  const descriptionTemplate = `Description/Summary of Problem (What/When):\n\nImpact to Customer (i.e. number of users, impact to day to day business):\n\nWhat is the customer expecting to see?\n\nWhat are they actually seeing?\n\nLink to Video (annotated if possible):\n\nObject Link (Project, Task, Document, etc.):`;

  // Use state to manage the value of the description textarea
  const [descriptionValue, setDescriptionValue] = useState(descriptionTemplate);

  // Use state to manage the values of the applicationAreas
  const [applicationAreas, setApplicationAreas] = useState<string[]>([]);

  const user = useSelector((state: RootState) => state.user.userDetails);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formData, setFormData] = useState<FormData | null>(null);
  const token = useSelector((state: RootState) => state.auth.token);

  //Async Function to fetch Application Areas from Workfront Hub via Fusion
  const getApplicationAreas = async () => {
    //Get Application Areas from Hub using Fusion
    try {
      let formData = {
        action: "getApplicationAreas",
      };
      let response = await fetch(webhookURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-OKTA-Authorization": `Bearer ${token}`,
        },
        body: JSON.stringify(formData),
      });
      if (response.ok) {
        let data = await response.json();
        setApplicationAreas(data);
      } else {
        // Handle errors, e.g., show an error message
        ToastQueue.negative(
          "Failed to load Application Areas for Defect Form. Please try again later.",
        );
        console.error(
          "Failed to load Application Areas for Defect Form",
          response.statusText,
        );
      }
    } catch (error) {
      ToastQueue.negative(
        "Failed to load Application Areas for Defect Form. Please try again later.",
      );
      console.error("Failed to load Application Areas for Defect Form.", error);
    }
  };

  const permissionCheck = useCallback(
    async (retryCount: number = 3, timeout: number = 5000) => {
      for (let attempt = 1; attempt <= retryCount; attempt++) {
        try {
          let controller = new AbortController();
          let fetchTimeout = setTimeout(() => controller.abort(), timeout);

          const formData = {
            user: user,
            action: "permissionCheck",
          };
          const response = await fetch(webhookURL, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "X-OKTA-Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify(formData),
            signal: controller.signal,
          });
          clearTimeout(fetchTimeout);

          const data = await response.json();

          switch (response.status) {
            case 401:
              setLoadingError(
                "Unauthorized. You do not have Tier 3 Support permissions",
              );
              setLoading(false);
              break;

            case 404:
              setLoadingError(
                "Unauthorized. This user was not found in Dynamics with Tier 3 Support Permissions",
              );
              setLoading(false);
              break;
            default:
              if (data["hasPermission"] === true) {
                await getApplicationAreas(); // Fetch application areas when the component mounts
                setLoadingError("");
                setLoading(false);
              }
              attempt = 3;
              break;
          }
        } catch (error) {
          if (attempt === retryCount) {
            setLoadingError(
              "Max Retries Reached. Failed to retrieve permissions for accessing this page. Please try again later. Is Fusion down?",
            );
          }
        }
      }
    },
    [user],
  );

  useEffect(() => {
    if (!componentMounted.current && user) {
      componentMounted.current = true;
      permissionCheck();
    }
  }, [user, permissionCheck]); // Empty dependency array ensures useEffect runs only once on mount

  //Function to handle template resetting
  const handleTemplateReset = () => {
    setDescriptionValue(descriptionTemplate);
  };

  const navigate = useNavigate();
  //Sets path for navigation to Workfront Automation page
  const handleBackToList = () => {
    navigate("/workfront");
  };

  //Function that handles form submission
  const formSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (isSubmitting) return;

    setIsSubmitting(true);

    const formData = {
      user: user,
      "defect-name": event.currentTarget["defect-name"].value,
      description: event.currentTarget["description"].value,
      "ticket-number": event.currentTarget["ticket-number"].value,
      crm: event.currentTarget["crm"].value,
      severity: event.currentTarget["severity"].value,
      "application-area": event.currentTarget["application-area"].value,
      environment: event.currentTarget["environment"].value,
      "steps-duplicate": event.currentTarget["steps-duplicate"].value,
      action: "createDefect",
    };

    try {
      const response = await fetch(webhookURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-OKTA-Authorization": `Bearer ${token}`,
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        // Handle successful webhook submission, e.g., show a success message
        ToastQueue.positive("Successfully submitted!");
        //Resets description template back to default
        handleTemplateReset();
        setFormSubmitted(true);
        setFormData(formData);
      } else {
        // Handle errors, e.g., show an error message
        ToastQueue.negative("Failed to submit form. Please try again later.");
        console.error(
          "Failed to submit form data to webhook:",
          response.statusText,
        );
      }
    } catch (error) {
      ToastQueue.negative("Failed to submit form. Please try again later.");
      console.error("Error submitting form data to webhook:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (formSubmitted && formData) {
    return <DefectConfirmation formData={formData} />;
  }

  const handleRetry = () => {
    setLoading(true); // Set loading state to true to display progress circle again
    setLoadingError(""); // Clear any previous error message
    permissionCheck();
  };

  //Returns loading error
  const loadingErrorView = (): JSX.Element => (
    <>
      <ContributionComponent
        title="Defect Form"
        developerEmail="nfinsand@adobe.com, grimaud@adobe.com"
        developerSlack="#oasis-slack"
      >
        <View
          marginTop={"size-250"}
          marginStart={"size-500"}
          marginEnd={"size-500"}
        >
          <Flex direction="column" width="100%" alignItems="center">
            <IllustratedMessage>
              <Unavailable />
              <Heading>Loading Error</Heading>
              <Content>{loadingError}</Content>
              <Button variant="cta" onPress={handleRetry} marginTop="10px">
                Retry
              </Button>
            </IllustratedMessage>
          </Flex>
        </View>
      </ContributionComponent>
    </>
  );

  //Returns loading view when loading
  const loadingView = (): JSX.Element => (
    <>
      <ContributionComponent
        title="Defect Form"
        developerEmail="nfinsand@adobe.com, grimaud@adobe.com"
        developerSlack="#oasis-slack"
      >
        <View
          marginTop={"size-250"}
          marginStart={"size-500"}
          marginEnd={"size-500"}
        >
          <Flex direction="column" width="100%" alignItems="center">
            <ProgressCircle
              isIndeterminate
              aria-label="Loading Progress Circle"
            />
          </Flex>
        </View>
      </ContributionComponent>
    </>
  );

  //Returns Form when loaded is successful
  const loadedView = (): JSX.Element => (
    <>
      <ContributionComponent
        title="Defect Form"
        developerEmail="nfinsand@adobe.com, grimaud@adobe.com"
        developerSlack="#oasis-slack"
      >
        <View
          marginTop={"size-250"}
          marginStart={"size-500"}
          marginEnd={"size-500"}
        >
          <Form
            onSubmit={formSubmit}
            validationBehavior="native"
            marginTop={"size-250"}
            marginStart={"size-500"}
            marginEnd={"size-500"}
          >
            <TextField label="Defect Name" name="defect-name" isRequired />
            <TextArea
              value={descriptionValue}
              onChange={setDescriptionValue}
              maxLength={2800}
              label="Description"
              name="description"
              isRequired
            />
            <TextField
              label="Ticket Number"
              name="ticket-number"
              type="text"
              isRequired
            />
            <ComboBox label="CRM" name="crm" isRequired>
              <Item>Dynamics</Item>
              <Item>Salesforce</Item>
            </ComboBox>
            <ComboBox label="Severity" name="severity" isRequired>
              <Item>Critical</Item>
              <Item>High</Item>
              <Item>Medium</Item>
              <Item>Low</Item>
            </ComboBox>

            <ComboBox
              label="Application Area"
              name="application-area"
              isRequired
              direction="top"
            >
              {applicationAreas.map((option) => (
                <Item key={option}>{option}</Item>
              ))}
            </ComboBox>

            <ComboBox
              label="Environment"
              name="environment"
              formValue="key"
              isRequired
            >
              <Item key="OnDemand">Production</Item>
              <Item key="Preview">Preview</Item>
              <Item key="Sandbox">Sandbox</Item>
            </ComboBox>

            <TextArea
              maxLength={1000}
              label="Steps To Duplicate"
              name="steps-duplicate"
              isRequired
            ></TextArea>
            <Divider size="M" marginTop="size-500" marginBottom="size-250" />
            <Flex justifyContent="end">
              <ButtonGroup>
                <Button
                  type="reset"
                  variant="secondary"
                  onPress={() => {
                    handleTemplateReset(); //Resets description template back to default
                    handleBackToList(); //Navigates back to the Workfront Automation page
                  }}
                >
                  Cancel
                </Button>

                <Button
                  type="submit"
                  variant="accent"
                  isDisabled={isSubmitting}
                >
                  {isSubmitting ? "Submitting..." : "Submit"}
                </Button>
              </ButtonGroup>
            </Flex>
          </Form>
        </View>
      </ContributionComponent>
    </>
  );

  if (loadingError) {
    return loadingErrorView();
  }

  if (loading) {
    return loadingView();
  }

  return loadedView();
};

export default DefectForm;

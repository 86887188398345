import React from "react";
import { Flex, Item, TabList, TabPanels, Tabs } from "@adobe/react-spectrum";
import "../../styles/main.scss";
import AccountDetailsTable from "./AccountDetailsTable";
import { useGetOrganizationQuery } from "../../services/supportInsights";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

const AccountDetailsTabContainer: React.FC = () => {
  return (
    <Tabs aria-label="Augmented Analysis Tabs">
      <Flex>
        <TabList
          UNSAFE_className="analytics-tabs"
          flex="1 1 auto"
          minWidth="0px"
        >
          <Item key="environment" data-analytics-link-name="Environment tab">
            Environment
          </Item>
          {/* <Item key="adobeteams" data-analytics-link-name="Adobe Teams tab">
            Adobe Teams
          </Item> */}
          {/* <Item key="orgnotes" data-analytics-link-name="Org Notes tab">
            Org Notes
          </Item> */}
        </TabList>
      </Flex>
      <TabPanels marginTop="size-200">
        <Item key="environment">
          <AccountDetailsTable />
        </Item>
        {/* <Item key="adobeteams">Nothing Yet in adobeteams</Item> */}
        <Item key="orgnotes">Nothing Yet in orgnotes</Item>
      </TabPanels>
    </Tabs>
  );
};

export default AccountDetailsTabContainer;

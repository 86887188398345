import {
  View,
  Flex,
  Item,
  MenuTrigger,
  Button,
  Menu,
} from "@adobe/react-spectrum";
import More from "@spectrum-icons/workflow/More";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/svg/adobe_logo.svg";
import Tag from "../../assets/svg/Tag.svg";
import ProfilePicture from "../../assets/profile.png";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import Banner from "./Banner";
import { setOpenPreferencesModal } from "../../store/userSlice";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { Key } from "react";
import { setSearchText } from "../../store/case/caseSlice";

interface NavbarProps {
  sticky?: boolean;
}

const menuItems = [
  {
    name: "User Preferences",
    key: "preferences",
  },
];

const handleMenuAction = (dispatch: Dispatch, key: Key) => {
  if (key === "preferences") {
    dispatch(setOpenPreferencesModal(true));
  }
};

const Navbar: React.FC<NavbarProps> = ({ sticky = false }) => {
  const user = useSelector((state: RootState) => state.user.userDetails);
  const isUserPersonaFeatureActive =
    localStorage.getItem("userPersonaFeatureActive") === "true";
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogoClick = () => {
    navigate("/", { replace: true });
    dispatch(setSearchText(""));
  };

  return (
    <View UNSAFE_className="sticky-navbar" height={"single-line-height"}>
      <Flex
        alignItems="center"
        justifyContent="start"
        direction="row"
        height="100%"
      >
        <div className="navbar-adobeLogo-div" onClick={handleLogoClick}>
          <img className="navbar-adobeLogo" src={logo} alt=""></img>
        </div>
        <div className="navbar-left-col-title" onClick={handleLogoClick}>
          DX Oasis
        </div>

        <Flex UNSAFE_className="navbar-left-col-Beta">
          <img src={Tag} alt="" aria-label="in Beta" />
        </Flex>
      </Flex>
      <Banner />

      <Flex alignItems="center" gap="size-150">
        <span id="navbar-user-name">{user?.name}</span>
        <img
          className="navbar-avatar"
          alt=""
          src={`https://s7d2.scene7.com/is/image/IMGDIR/${user?.userid || ""}?$70x$`}
          onError={(ev) => (ev.currentTarget.src = ProfilePicture)}
        />
        {/*TODO: Remove conditional when user persona features are complete*/}
        {isUserPersonaFeatureActive && (
          <MenuTrigger>
            <Button
              variant="primary"
              staticColor="white"
              style="outline"
              aria-label="Ring for service"
            >
              <More />
            </Button>
            <Menu
              UNSAFE_className="navbar-action-menu"
              items={menuItems}
              onAction={(key) => handleMenuAction(dispatch, key)}
            >
              {(item) => <Item>{item.name}</Item>}
            </Menu>
          </MenuTrigger>
        )}
      </Flex>
    </View>
  );
};

export default Navbar;

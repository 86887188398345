import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store/store";
import { setAiArticle } from "../store/case/caseSlice";
import { fetchAIResponseStream } from "../services/requestServices";

export function useAiArticle() {
  const dispatch = useDispatch();
  const caseObject = useSelector((state: RootState) => state.case.caseObject);
  const caseId = caseObject?.caseId || "";
  const aiArticle = useSelector((state: RootState) => state.case.aiArticle);
  const aiArticleStorage =
    localStorage.getItem("knowledgeArticle" + caseId) || "";
  const aiArticleText = aiArticle?.text || aiArticleStorage;
  const aiArticleIsLoading = aiArticle?.isLoading || false;

  const token = useSelector((state: RootState) => state.auth.token);

  const reload = () => {
    localStorage.removeItem("knowledgeArticle" + caseId);
    getAiArticle();
  };

  const getAiArticle = () => {
    const requestBody = {
      caseId,
      promptData: { "oasis-findings": [] },
    };
    const url = `${window.env.REACT_APP_API_BASE}dynamics/${caseId}/aiArticle`;
    dispatch(setAiArticle({ text: "Loading ...", isLoading: true }));
    fetchAIResponseStream(url, token, requestBody, (response) => {
      dispatch(setAiArticle(response));
    }).then((obj) => {
      if (obj?.error) {
        dispatch(
          setAiArticle({
            text: "Sorry, but AI suggestions are not available at the moment.",
            isLoading: false,
          }),
        );
      }
    });
  };

  return [aiArticleText, aiArticleIsLoading, getAiArticle, reload] as const;
}

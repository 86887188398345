import React, { useState, useRef, useEffect } from "react";
import {
  ActionButton,
  Button,
  Flex,
  TextArea,
  Text,
  View,
  Divider,
  ToggleButton,
  Content,
} from "@adobe/react-spectrum";
import MagicWand from "@spectrum-icons/workflow/MagicWand";
import PropertiesIcon from "@spectrum-icons/workflow/Properties";
import { suggestionsKeys, promptMatrix, PromptMatrixKey } from "./prompts";
import { useLocation } from "react-router-dom";
import PromptSelector from "./PromptSelector";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";

export interface AiFooterProps {
  askQuestion: (question: string) => void;
  setKey: (key: PromptMatrixKey) => void;
  promptVisible?: boolean;
  setPromptVisible?: (visible: boolean) => void;
  isLoading?: boolean;
  isHidden?: boolean;
}

const AiFooter: React.FC<AiFooterProps> = ({
  askQuestion,
  isLoading = false,
  isHidden = false,
  promptVisible,
  setPromptVisible = () => {},
  setKey,
}) => {
  const queryParams = new URLSearchParams(useLocation().search);
  const productFamily =
    useSelector((state: RootState) => state.case.caseObject)
      ?.productFamilyName || "";
  const [enteredText, setEnteredText] = useState<string>(
    queryParams.get("prompt") ?? "",
  );

  const bottomRef = useRef<HTMLDivElement>(null);
  const [screenFocusDown, setScreenFocusDown] = useState<boolean>(false);

  useEffect(() => {
    if (screenFocusDown && promptVisible && bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: "auto" });
    }
  }, [screenFocusDown]);

  return (
    <View>
      <Flex isHidden={isHidden} direction={"column"} gap={"single-line-height"}>
        {promptVisible && (
          <PromptSelector
            setSelfVisible={setPromptVisible}
            isLoading={isLoading}
            setKey={setKey}
          />
        )}
        <View
          padding={"size-300"}
          borderColor={"gray-300"}
          borderWidth={"thin"}
          borderRadius={"small"}
        >
          <TextArea
            isQuiet
            value={enteredText}
            onChange={setEnteredText}
            flex="1"
            width="100%"
            label={
              <em style={{ opacity: 0.5 }}>Hi, what would you like to know?</em>
            }
            onKeyDown={(e) => {
              if (e.key === "Enter" && (e.metaKey || e.ctrlKey)) {
                askQuestion(enteredText);
                setEnteredText("");
                setPromptVisible(false);
              }
            }}
          />
          <Flex direction={"row"} marginTop={"size-200"} gap={"size-200"}>
            <ToggleButton
              aria-label="Toggle prompt selector"
              isSelected={promptVisible}
              onChange={(selected) => {
                setPromptVisible(selected);
                setScreenFocusDown(selected);
              }}
            >
              <PropertiesIcon />
            </ToggleButton>
            <Divider orientation="vertical" size="S" />
            <Flex
              flex="1"
              direction="row"
              gap="size-100"
              alignItems="baseline"
              wrap="wrap"
            >
              <strong>Suggestions:</strong>
              {suggestionsKeys
                .filter(
                  (key) =>
                    !(
                      key === "investigate-summary-commerce-project" && productFamily !== "Commerce" ||
                      key === "aem-non-cso-rca" && !["Adobe Exp Manager", "Adobe Exp Platform"].includes(productFamily)
                    )
                )
                .map((key) => (
                  <Flex key={key} direction="row">
                    <ActionButton
                      onPress={() => {
                        setTimeout(() => {
                          setKey(key);
                          setPromptVisible(false);
                        }, 0);
                      }}
                      height="size-400"
                      staticColor="black"
                      isDisabled={isLoading}
                      data-analytics-link-name={promptMatrix[key].label}
                      data-analytics-view-name="AI Summary"
                      UNSAFE_className={`analytics-generate-ai-summary-button analytics-track-me analytics-generate-ai-summary-button--${key}`}
                    >
                      {promptMatrix[key].label}
                    </ActionButton>
                  </Flex>
                ))}
            </Flex>
            <Button
              variant="cta"
              data-analytics-link-name="Custom prompt"
              data-analytics-view-name="AI Summary"
              UNSAFE_className={`analytics-generate-ai-summary-button analytics-track-me analytics-generate-ai-summary-button--custom-prompt`}
              onPress={() => {
                if (!enteredText) return;
                askQuestion(enteredText);
                setEnteredText("");
                setPromptVisible(false);
              }}
              height="size-300"
              isDisabled={!enteredText || isLoading}
            >
              <Text>Send</Text>
              <MagicWand />
            </Button>
          </Flex>
        </View>
      </Flex>
      <View>
        <Flex>
          <Content
            UNSAFE_style={{
              fontSize: "8pt",
              color: "darkgray",
              marginTop: "10px",
            }}
          >
            <b>Disclaimer</b>: OASIS GenAI Summaries Chatbot (“OASIS Chat”) is
            intended to summarize support cases for support issue resolution
            purposes only. If you submit personal data to OASIS Chat”) you
            understand such personal information will be processed by Adobe (and
            service providers acting on Adobe’s behalf) in accordance with the
            Global Adobe Worker Privacy Policy and Supplemental Notices,
            including the Global Monitoring Notice, and you will limit your
            submission of personal data to OASIS Chat to the minimum needed to
            achieve the purpose.
          </Content>
        </Flex>
      </View>
      <div ref={bottomRef} />
    </View>
  );
};

export default AiFooter;

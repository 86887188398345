import { useEffect, useMemo, useState } from "react";
import {
  useGetOrganizationQuery,
  useGetProductFamilyQuery,
  useLazyGetProjectQuery,
  useLazyGetEnvironmentQuery,
} from "../services/supportInsights";
import { skipToken } from "@reduxjs/toolkit/query/react";
import {
  OrganizationResponse,
  ProductFamily,
  ProductFamilyResponse,
  EnvironmentResponse,
} from "../types/case";

interface Project {
  id: number;
  name: string;
  productFamilyId: number;
}

export const useAccountDetails = (orgId: string | undefined) => {
  const [allProjects, setAllProjects] = useState<Project[]>([]);
  const [environmentsMap, setEnvironmentsMap] = useState<{
    [key: number]: any[];
  }>({});
  const [isProjectsLoading, setIsProjectsLoading] = useState(true);

  // Fetch organization data
  const {
    data: organizationsList,
    isLoading: isOrgLoading,
    isError: isOrgError,
  } = useGetOrganizationQuery(orgId ? { orgId } : skipToken);

  // Fetch product families
  const validatedOrgId = (organizationsList as OrganizationResponse)?.data?.[0]
    ?.id;
  const { data: productFamiliesResponse, isLoading: isProductFamiliesLoading } =
    useGetProductFamilyQuery(
      validatedOrgId ? { orgId: validatedOrgId } : skipToken,
    );
  const productFamilies =
    (productFamiliesResponse as ProductFamilyResponse)?.data || [];

  const [triggerGetProjects] = useLazyGetProjectQuery();
  const [triggerGetEnvironments] = useLazyGetEnvironmentQuery();

  // Fetch projects for all product families
  useEffect(() => {
    if (!validatedOrgId || productFamilies.length === 0) {
      setIsProjectsLoading(false);
      return;
    }

    const fetchProjects = async () => {
      try {
        const projectPromises = productFamilies.map(
          async (family: ProductFamily) => {
            try {
              const projectsResponse = await triggerGetProjects({
                orgId: validatedOrgId,
                productFamilyId: family.id,
              }).unwrap();

              return (
                projectsResponse.data?.map((project) => ({
                  ...project,
                  productFamilyId: family.id,
                })) || []
              );
            } catch (error) {
              console.error(
                `Error fetching projects for family ID ${family.id}:`,
                error,
              );
              return [];
            }
          },
        );

        const projectsArray = await Promise.all(projectPromises);
        setAllProjects(projectsArray.flat());
      } catch (error) {
        console.error("Error in fetchProjects:", error);
      } finally {
        setIsProjectsLoading(false);
      }
    };

    fetchProjects();
  }, [validatedOrgId, productFamilies, triggerGetProjects]);

  // Fetch environments for each project
  useEffect(() => {
    if (!validatedOrgId || allProjects.length === 0) return;

    const fetchEnvironments = async () => {
      try {
        const environmentsData = await Promise.all(
          allProjects.map(async (project) => {
            try {
              const environmentsResponse = await triggerGetEnvironments({
                orgId: validatedOrgId,
                productFamilyId: project.productFamilyId,
                projectId: project.id,
              }).unwrap();
              return {
                projectId: project.id,
                environments: environmentsResponse.data || [],
              };
            } catch (error) {
              console.error(
                `Error fetching environments for project ${project.id}:`,
                error,
              );
              return { projectId: project.id, environments: [] };
            }
          }),
        );

        setEnvironmentsMap(
          environmentsData.reduce(
            (acc, envData) => {
              acc[envData.projectId] = envData.environments;
              return acc;
            },
            {} as { [key: number]: any[] },
          ),
        );
      } catch (error) {
        console.error("Error fetching environments:", error);
      }
    };

    fetchEnvironments();
  }, [allProjects, triggerGetEnvironments, validatedOrgId]);

  // Map product family IDs to names for easy lookup
  const productFamilyMap = useMemo(
    () =>
      Object.fromEntries(
        productFamilies.map((family) => [family.id, family.name]) || [],
      ),
    [productFamilies],
  );

  const dynamicData = useMemo(
    () =>
      allProjects.flatMap((project) => {
        const environments = environmentsMap[project.id] || [];
        return environments.length > 0
          ? environments.map((environment) => ({
              productFamilyName:
                productFamilyMap[project.productFamilyId] || "Unknown Solution",
              projectName: project.name,
              environmentName: environment.name || environment.id,
            }))
          : [
              {
                productFamilyName:
                  productFamilyMap[project.productFamilyId] ||
                  "Unknown Solution",
                projectName: project.name,
                environmentName: "None",
              },
            ];
      }),
    [allProjects, environmentsMap, productFamilyMap],
  );

  return {
    isOrgLoading,
    isProductFamiliesLoading,
    isProjectsLoading,
    isOrgError,
    dynamicData,
    productFamilies,
  };
};

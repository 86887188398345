import React from "react";
import { Flex, View, Text } from "@adobe/react-spectrum";
import WatchlistSwitch from "./../common/WatchlistSwitch";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { TicketInfoBannerElement } from "./TicketInfoBannerElement";
import { CaseInfo } from "../../types/case";
import { DSATCell } from "./DSATCell";
import SolutionStatusLogician from "./SolutionStatus";
import OrgNotes from "./OrgNotes";

const HelpText: Partial<Record<string, JSX.Element>> = {
  "Customer DSAT": (
    <>
      Aim to maintain Historical DSAT below 30%. The Predictive DSAT score is
      the probability that the customer interaction will result in a DSAT
      survey. This value is updated daily at 8:30 a.m. PT.
    </>
  ),
};

interface CaseNumberProps {
  caseNumber: string;
  ticketDbId: number;
}

const CaseNumber: React.FC<CaseNumberProps> = ({ caseNumber, ticketDbId }) => {
  const isUserPersonaFeatureActive =
    localStorage.getItem("userPersonaFeatureActive") === "true";
  return (
    <Flex gap={5} direction="column">
      <Text>{caseNumber}</Text>
      {/*TODO: Remove condition when user persona features are completed.*/}
      {isUserPersonaFeatureActive && (
        <WatchlistSwitch itemId={ticketDbId} itemType="ticket" />
      )}
    </Flex>
  );
};

const TicketInfoBanner: React.FC = () => {
  const ticketId = useSelector((state: RootState) => state.case.ticketId);
  const ticketDbId = useSelector((state: RootState) => state.case.ticketDbId);
  const orgId = useSelector((state: RootState) => state.case.caseObject?.orgId);
  const orgName = useSelector(
    (state: RootState) => state.case.caseObject?.orgName,
  );
  const orgInternalNotes = useSelector(
    (state: RootState) => state.case.caseObject?.internalNotes,
  );
  const family =
    useSelector(
      (state: RootState) => state.case.caseObject?.productFamilyName,
    ) ?? ":(";
  const reasons = [
    useSelector((state: RootState) => state.case.caseObject?.issueReasonText),
    useSelector(
      (state: RootState) => state.case.caseObject?.secondaryIssueReasonText,
    ),
  ]
    .filter(Boolean)
    .join("\n");

  const getAnalyticCaseFieldClass = (title: string) =>
    "analytics-case-" +
    title
      .replace(/\\W+/g, "-")
      .replace(/([a-z\\d])([A-Z])/g, "$1-$2")
      .replace(/\s+/g, "-")
      .toLowerCase();

  const ticketInfo: CaseInfo[] = [
    {
      title: "Case Number",
      value: <CaseNumber caseNumber={ticketId} ticketDbId={ticketDbId} />,
      size: 1,
      analyticCaseFieldClass: getAnalyticCaseFieldClass("Case Number"),
    },
    {
      title: "Solution",
      value: (
        <Flex direction="column">
          <Text UNSAFE_className={getAnalyticCaseFieldClass("Solution")}>
            {family}
          </Text>
          <SolutionStatusLogician />
        </Flex>
      ),
      size: 1,
      analyticCaseFieldClass: getAnalyticCaseFieldClass("Solution and Status"),
    },
    {
      title: "Org ID",
      value: orgId ?? "Not provided",
      size: 1,
      wideElementClass: "ticket-info__org-id",
      analyticCaseFieldClass: getAnalyticCaseFieldClass("Org ID"),
    },
    {
      title: "Org Name",
      value: (
        <Flex>
          {orgName ?? "Not provided"}
          {orgInternalNotes?.length ? (
            <OrgNotes internalNotes={orgInternalNotes ?? []} />
          ) : null}
        </Flex>
      ),
      size: 1,
      analyticCaseFieldClass: getAnalyticCaseFieldClass("Org Name"),
    },
    {
      title: "Contact Reason",
      value: reasons || "No reason provided",
      size: 2,
      wideElementClass: "ticket-info__org-id",
      analyticCaseFieldClass: getAnalyticCaseFieldClass("Contact Reason"),
    },
    {
      title: "Customer DSAT",
      value: DSATCell(family, ticketId),
      size: 3,
      wideElementClass: "ticket-info__org-id",
      analyticCaseFieldClass: getAnalyticCaseFieldClass("Customer DSAT"),
    },
  ];

  return (
    <View
      UNSAFE_className="ticket-info"
      backgroundColor="gray-50"
      borderRadius="regular"
      paddingX="size-100"
      paddingY="size-150"
    >
      {ticketInfo.map((info) => (
        <TicketInfoBannerElement
          title={info.title}
          value={info.value}
          help={HelpText[info.title as keyof CaseInfo]}
          size={info.size ?? 1}
          wideElementClass={info.wideElementClass}
          analyticCaseFieldClass={info.analyticCaseFieldClass}
        />
      ))}
    </View>
  );
};

export default TicketInfoBanner;
